import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import './Dashboard.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isConnected } from "../../../services/auth.service";
import { getCurrentCompany, updateCompany } from "../../../services/companies.service";
import { deleteOffer, getCurrentCompanyOffers } from "../../../services/offers.service";
import { getCurrentUser } from "../../../services/users.service";
import Swal from 'sweetalert2';
import { getBenefits } from "../../../services/public.service";
import { toast } from 'react-toastify';
import EditCompanyInfos from "../../../components/forms/editCompanyInfos/EditCompanyInfos";
import EditUserInfos from "../../../components/forms/editUserInfos/EditUserInfos";
import EditPassword from "../../../components/forms/editPassword/EditPassword";

export default function Dashboard() {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' });

  const [accountType, setAccountType] = React.useState('');
  const [userData, setUserData] = React.useState<any>({});
  const [companyData, setCompanyData] = React.useState<any>({});
  const [companyJobs, setCompanyJobs] = React.useState<any>({});
  const [editUserInfos, setEditUserInfos] = React.useState(false);
  const [editCompanyInfos, setEditCompanyInfos] = React.useState(false);
  const [editPassword, setEditPassword] = React.useState(false);
  const [editBenefits, setEditBenefits] = React.useState(false);
  const [collapseCompanyInfos, setCollapseCompanyInfos] = React.useState(true);
  const [collapseUserInfos, setCollapseUserInfos] = React.useState(true);
  const [collapsePassword, setCollapsePassword] = React.useState(true);
  const [collapseCompanyBenefits, setCollapseCompanyBenefits] = React.useState(false);
  const [collapseCompanyJobs, setCollapseCompanyJobs] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [availableBenefits, setAvailableBenefits] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (!isConnected()) {
      navigate('/login');
    } else {
      loadUserAndCompany();
    }
  }, []); // * [] to run only on mount and unmount to stop any infinite loops.

  /**
   * Load user and company with stats, providers, services & referrals
   */
  const loadUserAndCompany = async () => {
    setLoading(true);

    const user = await getCurrentUser();
    console.log('🚀 > loadUserAndCompany > user:', user);

    // * Check account type
    if (user && !user.companyId) {
      setUserData(user);
      setAccountType('candidate');

      setLoading(false);
      return;
    } else {
      setAccountType('company');
      await loadBenefits();
    }

    const company = await getCurrentCompany(navigate);
    console.log('🚀 > loadCompany > company:', company);

    if (company && Object.keys(company).length) {
      const jobs = await getCurrentCompanyOffers(navigate);
      console.log('🚀 > loadCompany > jobs:', jobs);

      if (company?.benefits?.length) {
        company.tempBenefits = [];

        for (const benefit of company.benefits) {
          company.tempBenefits.push(benefit._id);
        }
      }

      setUserData(user);
      setCompanyData(company);
      setCompanyJobs(jobs);
    }

    setLoading(false);
  };

  /**
   * Load benefits with type = both or company
   */
  const loadBenefits = async () => {
    const benefitsList = await getBenefits();

    if (benefitsList?.length) {
      const _benefits = [];

      for (const item of benefitsList) {
        if (item.type === 'both' || item.type === 'company') {
          _benefits.push({ _id: item._id, name: item.name });
        }
      }

      console.log('🚀 > loadBenefits > _benefits:', _benefits);
      setAvailableBenefits(_benefits);
    }
  };

  const addOrDeleteBenefit = (benefitId: string) => {
    if (companyData?.tempBenefits.includes(benefitId)) {
      const items = companyData.tempBenefits.filter((b: any) => b !== benefitId);
      const tempData = { ...companyData, tempBenefits: items };
      setCompanyData(tempData);
    } else {
      // Add new benefit
      const items = [...companyData.tempBenefits, benefitId];
      const tempData = { ...companyData, tempBenefits: items };
      setCompanyData(tempData);
    }
  };

  const saveBenefits = async () => {
    if (companyData.tempBenefits.length) {
      // ? ne faire l'appel que si changements ?
      await updateCompany({ ...companyData, benefits: companyData.tempBenefits }, navigate);
      await loadUserAndCompany();
      setEditBenefits(false);
    }
  };

  /**
   * Create an offer
   */
  const postJob = () => {
    navigate('/jobs/post');
  };

  /**
   * Display offer
   * @param jobId
   */
  const goToJob = (jobId: string) => {
    navigate(`/jobs?id=${jobId}`);
  };

  /**
   * Navigate to the job creation page with offer data
   * @param jobId
   */
  const editJob = (jobId: string) => {
    navigate(`/jobs/post?id=${jobId}`);
  };

  const deleteJob = async (jobId: string) => {
    const modal = await Swal.fire({
      title: t('confirmDelete'),
      showDenyButton: true,
      confirmButtonText: t('yes'),
      confirmButtonColor: '#e52020',
      denyButtonText: t('no'),
      denyButtonColor: '#777'
    });

    if (modal.isConfirmed) {
      const deleteResult = await deleteOffer(jobId, navigate);

      if (deleteResult?.deletedCount) {
        toast.error(t('deleteSuccess'));
        await loadUserAndCompany();
      } else {
        toast.error(t('deleteError'));
      }
    } else {
      return;
    }
  };

  return (
    <div className="container">
      {loading ?
        <div className='loader-container'>
          <div className="loader"></div>
        </div>
        : ''}

      {!loading && Object.keys(companyData).length || Object.keys(userData).length ? <div className="dashboard">
        {accountType === 'company' ?
          <div className="fab-container" onClick={postJob}>
            <button><FontAwesomeIcon icon="plus" /> {t('createOffer')}</button>
          </div> : ''}

        {/* Company infos */}
        {accountType === 'company' ?
          <div className="company-infos">
            {/* Header */}
            <div className="section-header">
              <div className="section-header-left">
                <h2>{t('companyInformations')}</h2>
                {!editCompanyInfos ?
                  <button className="edit-btn" onClick={() => {
                    setCollapseCompanyInfos(false);
                    setEditCompanyInfos(true);
                  }}>
                    <FontAwesomeIcon icon="pencil" /> <label className="edit-btn-text">{t('edit')}</label>
                  </button> : ''
                  // <button className="save-btn" onClick={() => saveCompanyInfos()}>
                  //   <FontAwesomeIcon icon="floppy-disk" /> {t('save')}
                  // </button>
                }
              </div>

              <div className="section-header-right">
                <button
                  onClick={() => {
                    setCollapseCompanyInfos(!collapseCompanyInfos);
                    setEditCompanyInfos(false);
                  }}>

                  {editCompanyInfos ?
                    <FontAwesomeIcon icon="xmark" /> :
                    <FontAwesomeIcon icon={collapseCompanyInfos ? "chevron-down" : "chevron-up"} />
                  }
                </button>
              </div>
            </div>

            {/* Content */}
            {!collapseCompanyInfos ? <div className="company-infos-content">
              {/* Infos */}
              <EditCompanyInfos
                readOnly={!editCompanyInfos}
                data={companyData}
                refreshParent={async () => {
                  await loadUserAndCompany();
                  setEditCompanyInfos(false);
                }}
              />
            </div> : ''}
          </div> : ''}

        {/* User infos */}
        <div className="user-infos">
          {/* Header */}
          <div className="section-header">
            <div className="section-header-left">
              <h2>{t('myInformations')}</h2>
              {!editUserInfos ?
                <button className="edit-btn" onClick={() => {
                  setCollapseUserInfos(false);
                  setEditUserInfos(true);
                }}>
                  <FontAwesomeIcon icon="pencil" /> <label className="edit-btn-text">{t('edit')}</label>
                </button> : ''
                // <button className="save-btn" onClick={() => saveUserInfos()}>
                //   <FontAwesomeIcon icon="floppy-disk" /> {t('save')}
                // </button>
              }
            </div>

            <div className="section-header-right">
              <button
                onClick={() => {
                  setCollapseUserInfos(!collapseUserInfos);
                  setEditUserInfos(false);
                }}>

                {editUserInfos ?
                  <FontAwesomeIcon icon="xmark" /> :
                  <FontAwesomeIcon icon={collapseUserInfos ? "chevron-down" : "chevron-up"} />
                }
              </button>
            </div>
          </div>

          {/* Content */}
          {!collapseUserInfos ? <div className="user-infos-content">
            {/* Infos */}
            <EditUserInfos
              readOnly={!editUserInfos}
              accountType={'user'}
              data={userData}
              refreshParent={async () => {
                await loadUserAndCompany();
                setEditUserInfos(false);
              }}
            />
          </div> : ''}
        </div>

        {/* Password */}
        <div className="user-infos">
          {/* Header */}
          <div className="section-header">
            <div className="section-header-left">
              <h2>{t('myPassword')}</h2>
              {!editPassword ?
                <button className="edit-btn" onClick={() => {
                  setCollapsePassword(false);
                  setEditPassword(true);
                }}>
                  <FontAwesomeIcon icon="pencil" /> <label className="edit-btn-text">{t('edit')}</label>
                </button> : ''
                // <button className="save-btn" onClick={() => saveUserInfos()}>
                //   <FontAwesomeIcon icon="floppy-disk" /> {t('save')}
                // </button>
              }
            </div>

            <div className="section-header-right">
              <button
                onClick={() => {
                  setCollapsePassword(!collapsePassword);
                  setEditPassword(false);
                }}>
                {editPassword ? <FontAwesomeIcon icon="xmark" /> : ''}
              </button>
            </div>
          </div>

          {/* Content */}
          {!collapsePassword ? <div className="password-infos-content">
            {/* Infos */}
            <EditPassword
              readOnly={!editPassword}
              refreshParent={async () => {
                await loadUserAndCompany();
                setEditPassword(false);
              }}
            />
          </div> : ''}
        </div>

        {/* Benefits */}
        {accountType === 'company' && companyData && Object.keys(companyData).length ?
          <div className="company-benefits">
            {/* Header */}
            <div className="section-header">
              <div className="section-header-left">
                <h2>{t('myBenefits')}</h2>
                {!editBenefits ?
                  <button className="edit-btn" onClick={() => {
                    setCollapseCompanyBenefits(false);
                    setEditBenefits(true);
                  }}>
                    <FontAwesomeIcon icon="pencil" /> <label className="edit-btn-text">{t('edit')}</label>
                  </button> :
                  <button className="save-btn" onClick={() => saveBenefits()}>
                    <FontAwesomeIcon icon="floppy-disk" /> {t('save')}
                  </button>
                }
              </div>

              <div className="section-header-right">
                <button
                  onClick={() => {
                    setCollapseCompanyBenefits(!collapseCompanyBenefits);
                    setEditBenefits(false);
                  }}>
                  {editBenefits ?
                    <FontAwesomeIcon icon="xmark" /> :
                    <FontAwesomeIcon icon={collapseCompanyBenefits ? "chevron-down" : "chevron-up"} />
                  }
                </button>
              </div>
            </div>

            {/* Content */}
            {!collapseCompanyBenefits ? <div className="company-benefits-content">
              {editBenefits ?
                <div className="company-benefits-edition-list">
                  {availableBenefits.map((item, key) => {
                    return (
                      <div
                        key={key}
                        className={"company-benefits-edition-list-item" + (companyData?.tempBenefits?.includes(item._id) ? " selected" : "")}
                        onClick={() => addOrDeleteBenefit(item._id)}>
                        {item.name}
                      </div>
                    );
                  })}
                </div> :
                <div className="company-benefits-list">
                  {companyData.benefits.map((item: any, index: number) => {
                    return (
                      <div className="company-benefits-list-item" key={index}>{item.name}</div>
                    );
                  })}
                </div>
              }
            </div> : ''}
          </div> : ''}

        {accountType === 'company' && companyJobs && Object.keys(companyJobs).length ?
          <div className="company-jobs">
            {/* Header */}
            <div className="section-header">
              <div className="section-header-left">
                <h2>{t('onlineOffers')}</h2>
              </div>

              <div className="section-header-right">
                <button
                  onClick={() => {
                    setCollapseCompanyJobs(!collapseCompanyJobs);
                  }}>
                  <FontAwesomeIcon icon={collapseCompanyJobs ? "chevron-down" : "chevron-up"} />
                </button>
              </div>
            </div>

            {/* Content */}
            {!collapseCompanyJobs ? <div className="company-jobs-list">
              {companyJobs.map((job: any, index: number) => {
                return (
                  <div
                    className="company-jobs-list-item"
                    key={index}
                  >
                    <div className="job-content-infos">
                      <div>
                        <span className="job-content-title">{job.title}</span>
                      </div>
                      <div className="job-content-infos-bottom">
                        <span className="job-content-infos-contract-type">{t(job.contractType)}</span>
                        <span>{job.activitySector} / {job.category}</span>
                        <span><FontAwesomeIcon icon="map-pin" /> {job.location}</span>
                      </div>
                    </div>
                    <div className="job-content-actions">
                      <button className="default-btn" onClick={() => goToJob(job._id)}><FontAwesomeIcon icon="eye" /> {t('see')}</button>
                      <button className="default-btn" onClick={() => editJob(job._id)}><FontAwesomeIcon icon="pencil" /> {t('edit')}</button>
                      <button className="delete-btn" onClick={() => deleteJob(job._id)}><FontAwesomeIcon icon="trash-can" /> {t('delete')}</button>
                    </div>
                  </div>
                );
              })}
            </div> : ''}
          </div> : ''}
      </div > : ''}
    </div >
  );
}