import React from "react";
import { useTranslation } from "react-i18next";
import './Register.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Constants } from "../../../utils/constants.utils";
import { useNavigate } from "react-router-dom";
import { register } from "../../../services/auth.service";
import { toast } from 'react-toastify';

export default function Register() {
  const { t } = useTranslation('translation', { keyPrefix: 'register' });

  const [accountType, setAccountType] = React.useState('candidate');
  const [accountData, setAccountData] = React.useState<any>({});
  const [formErrors, setFormErrors] = React.useState<any>({});
  const [submitted, setSubmitted] = React.useState(false);
  const navigate = useNavigate();

  const handleAccountType = (type: string) => {
    setAccountType(type);
    setAccountData({});
    setFormErrors({});
  };

  const handleFormData = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const tempData = { ...accountData, [event.target.name]: event.target.value }; // ! Used to check rules before state to be updated (async)
    setAccountData(tempData);

    if (submitted) {
      checkRules(tempData);
    }
  };

  const handleSubmit = async () => {
    console.log('🚀 > handleFormData > accountData:', accountData);
    setSubmitted(true);

    const errors = checkRules(accountData);

    // Return if error
    if (Object.keys(errors).length) {
      console.log('🚀 > handleSubmit > errors:', errors);
      toast.error(t('invalidFields'));
      setSubmitted(false);
      return;
    }

    // Format data for api
    let data: any = {
      user: {
        password: accountData.password,
        email: accountData.email,
        firstName: accountData.firstName,
        lastName: accountData.lastName,
      },
    };

    // Add company data if not empty
    if (accountType === 'company') {
      const companyObj = {
        name: accountData.name,
        siret: accountData.siret,
        address: accountData.address,
        zipCode: accountData.zipCode,
        city: accountData.city,
        country: accountData.country,
        phone: accountData.phone,
      };

      data = {
        ...data, company: companyObj
      };
    }

    const createResult = await register(data);

    if (createResult?.success) {
      toast.success(t('success'), { autoClose: 2000 });
      navigate('/login');
    } else {
      console.error('Register error.');
      toast.error(t('error'));
    }

    setSubmitted(false);
  };

  const checkRules = (data: any) => {
    const rules: any = accountType === 'candidate' ? Constants.candidateRegisterRules : Constants.companyRegisterRules;

    const errors: any = {};

    // Check every rules for account data
    for (const key of Object.keys(rules)) {
      if (rules[key].required && !data[key]) {
        errors[key] = t('required');
        continue;
      }

      if (rules[key].maxLength && data[key]?.length > rules[key].maxLength) {
        errors[key] = t('maxLength', { length: rules[key].maxLength });
      }

      if (rules[key].regex) {
        if (rules[key].regex === 'email' && !Constants.checkEmail(data[key])) {
          errors[key] = t('invalidEmail');
        }

        if (rules[key].regex === 'phone' && !Constants.checkPhone(data[key])) {
          errors[key] = t('invalidPhone');
        }
      }
    }

    // Check email & password confirmations
    if (data['email'] !== data['confirmEmail']) {
      errors['confirmEmail'] = t('emailConfirmationError');
    }

    if (data['password'] !== data['confirmPassword']) {
      errors['confirmPassword'] = t('passwordConfirmationError');
    }

    setFormErrors(errors);

    return errors;
  };

  return (
    <div className="container">
      <div className="register">
        <h1>{t('title')}</h1>

        {/* Company / Candidate */}
        <div className="register-account-type">
          <button
            className={accountType === 'candidate' ? 'selected' : ''}
            onClick={() => handleAccountType('candidate')}
          >
            <FontAwesomeIcon icon="user" /> {t('candidate')}
          </button>
          <button
            className={accountType === 'company' ? 'selected' : ''}
            onClick={() => handleAccountType('company')}
          >
            <FontAwesomeIcon icon="shop" /> {t('company')}
          </button>
        </div>

        {accountType === 'candidate' ?
          /**
           * CANDIDATE
           */
          <form className="register-form">
            {/* Name + lastname */}
            <div className="register-form-row">
              <div className="register-form-item flex-1">
                <label>{t('firstName')}*</label>
                <input type="text" name="firstName" onChange={handleFormData} />
                {formErrors?.firstName ? <span className="input-error">{formErrors?.firstName}</span> : ''}
              </div>
              <div className="register-form-item flex-1">
                <label>{t('lastName')}*</label>
                <input type="text" name="lastName" onChange={handleFormData} />
                {formErrors?.lastName ? <span className="input-error">{formErrors?.lastName}</span> : ''}
              </div>
            </div>

            {/* Email */}
            <div className="register-form-item flex-1">
              <label>{t('email')}*</label>
              <input type="text" name="email" onChange={handleFormData} />
              {formErrors?.email ? <span className="input-error">{formErrors?.email}</span> : ''}
            </div>
            <div className="register-form-item flex-1">
              <label>{t('confirmEmail')}*</label>
              <input type="text" name="confirmEmail" onChange={handleFormData} />
              {formErrors?.confirmEmail ? <span className="input-error">{formErrors?.confirmEmail}</span> : ''}
            </div>

            {/* Password */}
            <div className="register-form-row">
              <div className="register-form-item flex-1">
                <label>{t('password')}*</label>
                <input type="password" name="password" onChange={handleFormData} />
                {formErrors?.password ? <span className="input-error">{formErrors?.password}</span> : ''}
              </div>
              <div className="register-form-item flex-1">
                <label>{t('confirmPassword')}*</label>
                <input type="password" name="confirmPassword" onChange={handleFormData} />
                {formErrors?.confirmPassword ? <span className="input-error">{formErrors?.confirmPassword}</span> : ''}
              </div>
            </div>
          </form>
          :
          /**
           * COMPANY
           */
          <form className="register-form">
            <h2>Informations</h2>

            {/* Name + siret */}
            <div className="register-form-row">
              <div className="register-form-item flex-1">
                <label>{t('companyName')}*</label>
                <input type="text" name="name" onChange={handleFormData} />
                {formErrors?.name ? <span className="input-error">{formErrors?.name}</span> : ''}
              </div>
              <div className="register-form-item flex-1">
                <label>Siret</label>
                <input type="text" name="siret" onChange={handleFormData} />
                {formErrors?.siret ? <span className="input-error">{formErrors?.siret}</span> : ''}
              </div>
            </div>

            {/* // TODO rajouter les maxlength sur les inputs */}
            {/* Address */}
            <div className="register-form-row">
              <div className="register-form-item flex-3">
                <label>{t('address')}*</label>
                <input type="text" name="address" onChange={handleFormData} />
                {formErrors?.address ? <span className="input-error">{formErrors?.address}</span> : ''}
              </div>
              <div className="register-form-item flex-1">
                <label>{t('zipCode')}*</label>
                <input type="text" name="zipCode" onChange={handleFormData} />
                {formErrors?.zipCode ? <span className="input-error">{formErrors?.zipCode}</span> : ''}
              </div>
            </div>

            <div className="register-form-row">
              <div className="register-form-item flex-2">
                <label>{t('city')}*</label>
                <input type="text" name="city" onChange={handleFormData} />
                {formErrors?.city ? <span className="input-error">{formErrors?.city}</span> : ''}
              </div>
              <div className="register-form-item flex-2">
                <label>{t('country')}*</label>
                <input type="text" name="country" onChange={handleFormData} />
                {formErrors?.country ? <span className="input-error">{formErrors?.country}</span> : ''}
              </div>
              <div className="register-form-item flex-3">
                <label>{t('phone')}*</label>
                <input type="text" name="phone" onChange={handleFormData} />
                {formErrors?.phone ? <span className="input-error">{formErrors?.phone}</span> : ''}
              </div>
            </div>

            <h2>Contact</h2>
            {/* Name + lastname */}
            <div className="register-form-row">
              <div className="register-form-item flex-1">
                <label>{t('firstName')}*</label>
                <input type="text" name="firstName" onChange={handleFormData} />
                {formErrors?.firstName ? <span className="input-error">{formErrors?.firstName}</span> : ''}
              </div>
              <div className="register-form-item flex-1">
                <label>{t('lastName')}*</label>
                <input type="text" name="lastName" onChange={handleFormData} />
                {formErrors?.lastName ? <span className="input-error">{formErrors?.lastName}</span> : ''}
              </div>
            </div>

            {/* Email */}
            <div className="register-form-item flex-1">
              <label>{t('email')}*</label>
              <input type="text" name="email" onChange={handleFormData} />
              {formErrors?.email ? <span className="input-error">{formErrors?.email}</span> : ''}
            </div>
            <div className="register-form-item flex-1">
              <label>{t('confirmEmail')}*</label>
              <input type="text" name="confirmEmail" onChange={handleFormData} />
              {formErrors?.confirmEmail ? <span className="input-error">{formErrors?.confirmEmail}</span> : ''}
            </div>

            {/* Password */}
            <div className="register-form-row">
              <div className="register-form-item flex-1">
                <label>{t('password')}*</label>
                <input type="password" name="password" onChange={handleFormData} />
                {formErrors?.password ? <span className="input-error">{formErrors?.password}</span> : ''}
              </div>
              <div className="register-form-item flex-1">
                <label>{t('confirmPassword')}*</label>
                <input type="password" name="confirmPassword" onChange={handleFormData} />
                {formErrors?.confirmPassword ? <span className="input-error">{formErrors?.confirmPassword}</span> : ''}
              </div>
            </div>
          </form>
        }

        <button
          type="submit"
          className="submit-btn"
          disabled={submitted}
          onClick={handleSubmit}
        >{t('validate')} <FontAwesomeIcon icon="check" /></button>
      </div>
    </div>
  );
}