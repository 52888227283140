import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import './Navbar.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isConnected, logout } from "../../services/auth.service";

export default function Navbar() {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'navbar' });

  const token = isConnected()

  const logoutUser = () => {
    logout(navigate, '/', false)
  }

  return (
    <div className="container">
      <div className="navbar" >
        <div className="navbar-logo">
          <span onClick={() => navigate('/')}><FontAwesomeIcon icon="fire" /> Mon job</span>
        </div>

        <div className="navbar-menu">
          <div className="navbar-menu-item">
            <Link to="/">{t('home')}</Link>
          </div>

          {!token ?
            <div className="navbar-menu-item">
              <Link to="/login">{t('login')}</Link>
            </div>
            : ''}

          {/* Connected */}
          {token ?
            <div className="navbar-menu-item">
              <Link to="/dashboard">{t('account')}</Link>
            </div>
            : ''}
          {token ?
            <div className="navbar-menu-item logout" onClick={logoutUser}>
              <FontAwesomeIcon icon={['fas', 'sign-out']} />
            </div>
            : ''}
        </div>
      </div>
    </div>
  );
}