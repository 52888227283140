import React from "react";
import { useTranslation } from "react-i18next";
import './JobCreation.scss';
import { Constants } from "../../../utils/constants.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { createOffer, updateOffer } from "../../../services/offers.service";
import { useNavigate } from "react-router-dom";
import { isConnected } from "../../../services/auth.service";
import { getCurrentUser } from "../../../services/users.service";
import { toast } from 'react-toastify';
import { getJobsList } from "../../../utils/jobsList.utils";
import { getBenefits, getOfferById } from "../../../services/public.service";

export default function JobCreation() {
  const { t } = useTranslation('translation', { keyPrefix: 'job' });
  const navigate = useNavigate();

  const [jobData, setJobData] = React.useState<any>();
  const [jobId, setJobId] = React.useState('');
  const [formErrors, setFormErrors] = React.useState<any>({});
  const [submitted, setSubmitted] = React.useState(false);
  const [sectors, setSectors] = React.useState<string[]>([]);
  const [categories, setCategories] = React.useState<string[]>([]);
  const [availableBenefits, setAvailableBenefits] = React.useState<any[]>([]);
  const [mode, setMode] = React.useState('creation');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    console.log('🚀 > JobCreation > jobData:', jobData);
    if (!isConnected()) {
      navigate('/login');
    }

    const queryParameters = new URLSearchParams(window.location.search);
    const idParam = queryParameters.get("id");

    if (idParam?.length) {
      setJobId(idParam);
      loadUserAndJob(idParam);
    } else {
      loadUser();
    }
  }, []);

  const loadUserAndJob = async (jobId: string) => {
    setLoading(true);

    const job = await getOfferById(jobId);

    if (job?._id) {
      // Load user
      const user = await getCurrentUser(navigate);

      // Check job companyId equal to user companyId
      if (user && user.companyId === job.companyId) {
        job.contact = user.email;

        await loadSectors();
        await loadBenefits();
        await loadCategories(job.activitySector);

        // Convert benefits array of objects to array of ids
        if (job?.benefits?.length) {
          const benefitsIds = job.benefits.map((b: any) => b._id);
          job.benefits = benefitsIds;
        }

        setMode('edition');
        setJobData(job);
      } else {
        toast.error(t('accessDenied'));
        navigate('/dashboard');
      }
    } else {
      toast.error(t('notFound'));
      navigate('/');
    }

    setLoading(false);
  };

  const loadUser = async () => {
    const user = await getCurrentUser(navigate);
    console.log('🚀 > loadUser > user:', user);

    setLoading(true);

    if (user?.companyId) {
      await loadSectors();
      await loadBenefits();
    } else {
      toast.error(t('accessDenied'));
      navigate('/');
    }

    setJobData({
      title: '',
      description: '',
      activitySector: '',
      category: '',
      location: '',
      contractType: 'openEndedContract',
      benefits: [],
      contact: user.email
    });

    setLoading(false);
  };

  const loadSectors = async () => {
    const jobsList = getJobsList();
    const keys: string[] = Object.keys(jobsList);
    setSectors(keys.sort((a, b) => a.localeCompare(b)));
  };

  const loadCategories = async (sector: string) => {
    const jobsList: any = getJobsList();
    setCategories(jobsList[sector]);
  };

  /**
   * Load benefits with type = both or job
   */
  const loadBenefits = async () => {
    const benefitsList = await getBenefits();

    if (benefitsList?.length) {
      const _benefits = [];

      for (const item of benefitsList) {
        if (item.type === 'both' || item.type === 'job') {
          _benefits.push({ _id: item._id, name: item.name });
        }
      }

      console.log('🚀 > loadBenefits > _benefits:', _benefits);
      setAvailableBenefits(_benefits);
    }
  };

  /**
   * Handle normal input data from form
   * @param event
   */
  const handleFormData = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const tempData = { ...jobData, [event.target.name]: event.target.value }; // ! Used to check rules before state to be updated (async)
    setJobData(tempData);

    if (submitted) {
      checkRules(tempData); // * Pass tempData to checkRules to avoid waiting for the state refresh
    }
  };


  const handleEditor = async (value: string) => {
    const tempData = { ...jobData, description: value };
    setJobData(tempData);

    if (submitted) {
      checkRules(tempData);
    }
  };

  /**
   * Handle contract type with radio input
   * @param event
   */
  const handleContractType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tempData = { ...jobData, contractType: event.target.name };
    setJobData(tempData);

    if (submitted) {
      checkRules(tempData);
    }
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    const { name, value } = event.target;
    const tempData = { ...jobData, [name]: value };
    setJobData(tempData);

    if (name === 'activitySector') {
      loadCategories(value);
    }

    if (submitted) {
      checkRules(tempData);
    }
  };

  const addOrDeleteBenefit = (benefitId: string) => {
    if (jobData?.benefits.includes(benefitId)) {
      const items = jobData.benefits.filter((b: any) => b !== benefitId);
      const tempData = { ...jobData, benefits: items }; // ! Used to check rules before state to be updated (async)
      setJobData(tempData);
    } else {
      // Add new benefit
      const items = [...jobData.benefits, benefitId];
      const tempData = { ...jobData, benefits: items };
      setJobData(tempData);
    }
  };

  /**
   * Handle form validation
   * @returns
   */
  const handleSubmit = async () => {
    console.log('🚀 > handleSubmit > jobData:', jobData);

    setSubmitted(true);

    const errors = checkRules(jobData);

    // Return if error
    if (Object.keys(errors).length) {
      console.log('🚀 > handleSubmit > errors:', errors);
      toast.error(t('invalidFields'));
      setSubmitted(false);
      return;
    }

    // Adapt api call with mode
    if (mode === 'creation') {
      const createResult = await createOffer(jobData, navigate);

      console.log('🚀 > handleSubmit > createResult:', createResult);

      if (createResult?._id) {
        toast.success(t('creationSuccess'), { autoClose: 2000 });
        navigate('/dashboard');
      } else {
        toast.error(t('creationError'));
      }
    } else if (mode === 'edition' && jobId) {
      const updateResult = await updateOffer(jobId, jobData, navigate);

      if (updateResult.modifiedCount) {
        toast.success(t('editionSuccess'), { autoClose: 2000 });
        navigate('/dashboard');
      } else {
        toast.error(t('editionError'));
      }
    }

    setSubmitted(false);
  };

  /**
   * Generate model for description
   */
  const generateDescription = () => {
    setJobData({ ...jobData, description: Constants.defaultDescription });
  };

  /**
   * Check form data with description & contractType
   * @param data
   * @returns
   */
  const checkRules = (data: any) => {
    const rules: any = Constants.jobCreationRules;
    const errors: any = {};

    // Check every rules for account data
    for (const key of Object.keys(rules)) {
      if (rules[key].required && !data[key]) {
        errors[key] = t('required');
        continue;
      }

      if (rules[key].maxLength && data[key]?.length > rules[key].maxLength) {
        errors[key] = t('maxLength', { length: rules[key].maxLength });
      }
    }

    setFormErrors(errors);

    return errors;
  };

  return (
    <div className="container">
      {!loading ?
        <div>
          {jobData ? <div className="job-creation" >
            <form className="job-creation-form">
              {/* Title */}
              <div className="job-creation-form-item">
                <label>{t('title')}*</label>

                <input type="text" name="title" maxLength={250} value={jobData?.title} onChange={handleFormData} />

                {formErrors?.title ? <span className="input-error">{formErrors?.title}</span> : ''}
              </div>

              {/* Description */}
              <div className="job-creation-form-item">
                <label>{t('description')}*</label>

                <ReactQuill
                  theme="snow"
                  formats={Constants.editorFormats}
                  modules={Constants.editorModules}
                  value={jobData.description}
                  onChange={handleEditor}
                />

                <button
                  className="generate-description-btn"
                  type="button"
                  onClick={() => generateDescription()}
                >{t('generateTemplate')}</button>

                {formErrors?.description ? <span className="input-error">{formErrors?.description}</span> : ''}
              </div>

              {/* Activity sector */}
              <div className="job-creation-form-item">
                <label>{t('activitySector')}*</label>

                <div className="select-container">
                  <select name="activitySector" onChange={handleSelect} value={jobData.activitySector ?? ''}>
                    <option disabled value="">{t('chooseActivitySector')}</option>
                    {sectors.map((item: any, index) => {
                      return (
                        // TODO à traduire plus tard
                        <option key={index} value={item}>{item}</option>
                      );
                    })}
                  </select>
                </div>

                {formErrors?.activitySector ? <span className="input-error">{formErrors?.activitySector}</span> : ''}
              </div>

              {/* Category */}
              <div className="job-creation-form-item">
                <label>{t('category')}*</label>

                <div className="select-container">
                  <select disabled={!jobData.activitySector} name="category" onChange={handleSelect} value={jobData.category ?? ''}>
                    <option disabled value="">{t('chooseCategory')}</option>
                    {categories.map((item: any, index) => {
                      return (
                        // TODO à traduire plus tard
                        <option key={index} value={item}>{item}</option>
                      );
                    })}
                  </select>
                </div>

                {formErrors?.category ? <span className="input-error">{formErrors?.category}</span> : ''}
              </div>

              {/* Location */}
              <div className="job-creation-form-item">
                <label>{t('location')}*</label>

                <input type="text" name="location" maxLength={250} value={jobData?.location} onChange={handleFormData} />

                {formErrors?.location ? <span className="input-error">{formErrors?.location}</span> : ''}
              </div>

              {/* Contract type */}
              <div className="job-creation-form-item">
                <label>{t('contractType')}*</label>

                {Constants.contractTypes.map((name: string, index) => {
                  return (
                    <label className="contract-type-checkbox" key={index}>
                      <input
                        name={name}
                        type="checkbox"
                        checked={jobData.contractType === name}
                        onChange={handleContractType}
                      />
                      <span>{t(name)}</span>
                    </label>
                  );
                })}

                {formErrors?.contractType ? <span className="input-error">{formErrors?.contractType}</span> : ''}
              </div>

              {/* Benefits */}
              <div className="job-creation-form-item">
                <label>{t('benefits')}</label>

                {availableBenefits?.length ?
                  <div className="benefits-list">
                    {availableBenefits.map((item, key) => {
                      return (
                        <div
                          key={key}
                          className={"benefits-list-item" + (jobData?.benefits.includes(item._id) ? " selected" : "")}
                          onClick={() => addOrDeleteBenefit(item._id)}>
                          {item.name}
                        </div>
                      );
                    })}
                  </div> : ''}
              </div>

              {/* Contact */}
              <div className="job-creation-form-item">
                <label>{t('contact')}*</label>
                <input type="text" name="contact" maxLength={500} value={jobData?.contact} onChange={handleFormData} />
                {formErrors?.contact ? <span className="input-error">{formErrors?.contact}</span> : ''}
              </div>
            </form>

            <button
              type="submit"
              disabled={submitted}
              className="submit-btn"
              onClick={handleSubmit}
            >{t('validate')} <FontAwesomeIcon icon="check" /></button>
          </div> : ''}
        </div> :
        <div className='loader-container'>
          <div className="loader"></div>
        </div>
      }
    </div>
  );
}