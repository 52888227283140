export function getJobsList() {
    return {
        "Agriculteurs (salariés de leur exploitation)": [
            "Agriculteurs et éleveurs, salariés de leur exploitation"
        ],
        "Artisans (salariés de leur entreprise)": [
            "Artisans salariés de leur entreprise"
        ],
        "Cadres administratifs et commerciaux d'entreprises": [
            "Cadres chargés d'études économiques, financières, commerciales",
            "Cadres commerciaux de la banque",
            "Cadres commerciaux des grandes entreprises (hors commerce de détail)",
            "Cadres commerciaux des petites et moyennes entreprises (hors commerce de détail)",
            "Cadres d'état-major administratifs, financiers, commerciaux des grandes entreprises",
            "Cadres de l'exploitation des magasins de vente du commerce de détail",
            "Cadres de l'hôtellerie et de la restauration",
            "Cadres de l'immobilier",
            "Cadres de l'organisation ou du contrôle des services administratifs et financiers",
            "Cadres de la documentation, de l'archivage (hors fonction publique)",
            "Cadres de la publicité",
            "Cadres des autres services administratifs des grandes entreprises",
            "Cadres des autres services administratifs des petites et moyennes entreprises",
            "Cadres des marchés financiers",
            "Cadres des opérations bancaires",
            "Cadres des relations publiques et de la communication",
            "Cadres des services financiers ou comptables des grandes entreprises",
            "Cadres des services financiers ou comptables des petites et moyennes entreprises",
            "Cadres des services techniques des assurances",
            "Cadres des services techniques des organismes de sécurité sociale et assimilés",
            "Cadres spécialistes de la formation",
            "Cadres spécialistes des ressources humaines et du recrutement",
            "Chefs d'établissements et responsables de l'exploitation bancaire",
            "Chefs de produits, acheteurs du commerce et autres cadres de la mercatique",
            "Juristes"
        ],
        "Cadres de la fonction publique": [
            "Autres personnels administratifs de catégorie A de l'Etat (hors Enseignement, Patrimoine, Impôts, Trésor, Douanes)",
            "Cadres administratifs de France Télécom (statut public)",
            "Cadres de la Poste",
            "Ingénieurs de l'Etat (y.c. ingénieurs militaires) et assimilés",
            "Ingénieurs des collectivités locales et des hôpitaux",
            "Inspecteurs et autres personnels de catégorie A des Impôts, du Trésor et des Douanes",
            "Magistrats",
            "Officiers des Armées et de la Gendarmerie (sauf officiers généraux)",
            "Personnels administratifs de catégorie A des collectivités locales et hôpitaux publics (hors Enseignement, Patrimoine)",
            "Personnes exerçant un mandat politique ou syndical"
        ],
        "Chauffeurs": [
            "Conducteurs de taxi",
            "Conducteurs de voiture particulière",
            "Conducteurs de véhicule de ramassage des ordures ménagères",
            "Conducteurs de véhicule routier de transport en commun",
            "Conducteurs livreurs, coursiers",
            "Conducteurs routiers et grands routiers"
        ],
        "Chefs d'entreprise de 10 salariés ou plus (salariés de leur entreprise)": [
            "Chefs d'entreprise commerciale, de 10 à 49 salariés",
            "Chefs d'entreprise de l'industrie ou des transports, de 10 à 49 salariés",
            "Chefs d'entreprise de services, de 10 à 49 salariés",
            "Chefs d'entreprise du bâtiment et des travaux publics, de 10 à 49 salariés",
            "Chefs de grande entreprise de 500 salariés et plus",
            "Chefs de moyenne entreprise, de 50 à 499 salariés"
        ],
        "Clergé, religieux": [
            "Clergé régulier",
            "Clergé séculier"
        ],
        "Commerçants et assimilés (salariés de leur entreprise)": [
            "Commerçants et assimilés, salariés de leur entreprise"
        ],
        "Contremaîtres, agents de maîtrise (maîtrise administrative exclue)": [
            "Agents de maîtrise en construction mécanique, travail des métaux",
            "Agents de maîtrise en entretien général, installation, travaux neufs (hors mécanique, électromécanique, électronique)",
            "Agents de maîtrise en fabrication : agroalimentaire, chimie, plasturgie, pharmacie.",
            "Agents de maîtrise en fabrication : métallurgie, matériaux lourds et autres industries de transformation",
            "Agents de maîtrise en fabrication de matériel électrique, électronique",
            "Agents de maîtrise en fabrication des autres industries (imprimerie, matériaux souples, ameublement et bois)",
            "Agents de maîtrise en maintenance, installation en mécanique",
            "Agents de maîtrise en maintenance, installation en électricité et électronique",
            "Agents de maîtrise en maintenance, installation en électromécanique",
            "Agents de maîtrise et techniciens en production et distribution d'énergie, eau, chauffage",
            "Chefs de chantier (non cadres)",
            "Conducteurs de travaux (non cadres)",
            "Contremaîtres et agents d'encadrement (non cadres) en agriculture, sylviculture",
            "Maîtres d'équipage de la marine marchande et de la pêche",
            "Maîtrise de restauration  : cuisine/production",
            "Maîtrise de restauration  : gestion d'établissement",
            "Responsables d'entrepôt, de magasinage",
            "Responsables du tri, de l'emballage, de l'expédition et autres responsables de la manutention"
        ],
        "Employés administratifs d'entreprise": [
            "Agents d'accueil non qualifiés",
            "Agents d'accueil qualifiés, hôtesses d'accueil et d'information",
            "Agents des services commerciaux des transports de voyageurs et du tourisme",
            "Autres agents et hôtesses d'accompagnement (transports, tourisme)",
            "Contrôleurs des transports (personnels roulants)",
            "Dactylos, sténodactylos (sans secrétariat), opérateurs de traitement de texte",
            "Employés administratifs d'exploitation des transports de marchandises",
            "Employés administratifs des services techniques de la banque",
            "Employés administratifs non qualifiés",
            "Employés administratifs qualifiés des autres services des entreprises",
            "Employés des services commerciaux de la banque",
            "Employés des services techniques des assurances",
            "Employés des services techniques des organismes de sécurité sociale et assimilés",
            "Employés et opérateurs d'exploitation en informatique",
            "Employés non qualifiés des services comptables ou financiers",
            "Employés qualifiés des services commerciaux des entreprises (hors vente)",
            "Employés qualifiés des services comptables ou financiers",
            "Employés qualifiés des services du personnel et des services juridiques",
            "Hôtesses de l'air et stewards",
            "Secrétaires",
            "Standardistes, téléphonistes"
        ],
        "Employés civils et agents de service de la fonction publique": [
            "Adjoints administratifs de l'Etat et assimilés (sauf Poste, France Télécom)",
            "Adjoints administratifs de la fonction publique (y c. enseignement)",
            "Adjoints administratifs des collectivités locales",
            "Adjoints administratifs des hôpitaux publics",
            "Agents administratifs de l'Etat et assimilés (sauf Poste, France Télécom)",
            "Agents administratifs de la fonction publique (y c. enseignement)",
            "Agents administratifs des collectivités locales",
            "Agents administratifs des hôpitaux publics",
            "Agents de constatation ou de recouvrement des Impôts, du Trésor, des Douanes",
            "Agents de service de la fonction publique (sauf écoles, hôpitaux)",
            "Agents de service des autres établissements d'enseignement",
            "Agents de service des établissements primaires",
            "Agents de service hospitaliers",
            "Aides médico-psychologiques",
            "Aides-soignants",
            "Ambulanciers salariés",
            "Assistants dentaires, médicaux et vétérinaires, aides de techniciens médicaux",
            "Auxiliaires de puériculture",
            "Employés de France Télécom (statut public)",
            "Employés de la Poste"
        ],
        "Employés de commerce": [
            "Autres vendeurs non spécialisés",
            "Caissiers de magasin",
            "Employés de libre service du commerce et magasiniers",
            "Pompistes et gérants de station-service (salariés ou mandataires)",
            "Vendeurs de biens culturels (livres, disques, multimédia, objets d'art)",
            "Vendeurs de tabac, presse et articles divers",
            "Vendeurs du commerce de fleurs",
            "Vendeurs en alimentation",
            "Vendeurs en ameublement, décor, équipement du foyer",
            "Vendeurs en droguerie, bazar, quincaillerie, bricolage",
            "Vendeurs en habillement et articles de sport",
            "Vendeurs en produits de beauté, de luxe (hors biens culturels) et optique",
            "Vendeurs par correspondance, télévendeurs",
            "Vendeurs polyvalents des grands magasins"
        ],
        "Ingénieurs et cadres techniques d'entreprises": [
            "Architectes salariés",
            "Chefs de projets informatiques, responsables informatiques",
            "Directeurs techniques des grandes entreprises",
            "Ingénieurs et cadres d'administration, maintenance, support et services aux utilisateurs en informatique",
            "Ingénieurs et cadres d'étude du bâtiment et des travaux publics",
            "Ingénieurs et cadres d'étude et développement de l'agriculture, la pêche, les eaux et forêts",
            "Ingénieurs et cadres d'étude, recherche et développement de la distribution d'énergie, eau",
            "Ingénieurs et cadres d'étude, recherche et développement des autres industries (imprimerie, matériaux souples, ameublement et bois)",
            "Ingénieurs et cadres d'étude, recherche et développement des industries de transformation (agroalimentaire, chimie, métallurgie, matériaux lourds)",
            "Ingénieurs et cadres d'étude, recherche et développement en informatique",
            "Ingénieurs et cadres d'étude, recherche et développement en mécanique et travail des métaux",
            "Ingénieurs et cadres d'étude, recherche et développement en électricité, électronique",
            "Ingénieurs et cadres de fabrication des autres industries (imprimerie, matériaux souples, ameublement et bois)",
            "Ingénieurs et cadres de fabrication des industries de transformation (agroalimentaire, chimie, métallurgie, matériaux lourds)",
            "Ingénieurs et cadres de fabrication en matériel électrique, électronique",
            "Ingénieurs et cadres de fabrication en mécanique et travail des métaux",
            "Ingénieurs et cadres de la logistique, du planning et de l'ordonnancement",
            "Ingénieurs et cadres de la maintenance, de l'entretien et des travaux neufs",
            "Ingénieurs et cadres de la production et de la distribution d'énergie, eau",
            "Ingénieurs et cadres de production et d'exploitation de l'agriculture, la pêche, les eaux et forêts",
            "Ingénieurs et cadres des achats et approvisionnements industriels",
            "Ingénieurs et cadres des méthodes de production",
            "Ingénieurs et cadres du contrôle-qualité",
            "Ingénieurs et cadres spécialistes des télécommunications",
            "Ingénieurs et cadres technico-commerciaux des industries de transformations (biens intermédiaires)",
            "Ingénieurs et cadres technico-commerciaux en bâtiment, travaux publics",
            "Ingénieurs et cadres technico-commerciaux en informatique et télécommunications",
            "Ingénieurs et cadres technico-commerciaux en matériel mécanique professionnel",
            "Ingénieurs et cadres technico-commerciaux en matériel électrique ou électronique professionnel",
            "Ingénieurs et cadres techniques de l'environnement",
            "Ingénieurs et cadres techniques de l'exploitation des transports",
            "Ingénieurs, cadres de chantier et conducteurs de travaux (cadres) du bâtiment et des travaux publics",
            "Officiers et cadres navigants techniques de la marine marchande",
            "Officiers et cadres navigants techniques et commerciaux de l'aviation civile"
        ],
        "Ouvriers agricoles et assimilés": [
            "Conducteurs d'engin agricole ou forestier",
            "Marins-pêcheurs et ouvriers de l'aquaculture",
            "Ouvriers agricoles sans spécialisation particulière",
            "Ouvriers de l'exploitation forestière ou de la sylviculture",
            "Ouvriers de l'élevage",
            "Ouvriers de la viticulture ou de l'arboriculture fruitière",
            "Ouvriers du maraîchage ou de l'horticulture"
        ],
        "Ouvriers non qualifiés de type artisanal": [
            "Apprentis boulangers, bouchers, charcutiers",
            "Métalliers, serruriers, réparateurs en mécanique non qualifiés",
            "Nettoyeurs",
            "Ouvriers non qualifiés de l'assainissement et du traitement des déchets",
            "Ouvriers non qualifiés divers de type artisanal",
            "Ouvriers non qualifiés du gros oeuvre du bâtiment",
            "Ouvriers non qualifiés du second oeuvre du bâtiment"
        ],
        "Ouvriers non qualifiés de type industriel": [
            "Agents non qualifiés des services d'exploitation des transports",
            "Aides-mineurs, ouvriers non qualifiés de l'extraction",
            "Autres ouvriers de production non qualifiés : industrie agroalimentaire",
            "Déménageurs (hors chauffeurs-déménageurs), non qualifiés",
            "Manutentionnaires non qualifiés",
            "Ouvriers de production non qualifiés : chimie, pharmacie, plasturgie",
            "Ouvriers de production non qualifiés : industrie lourde du bois, fabrication des papiers et cartons",
            "Ouvriers de production non qualifiés : métallurgie, production verrière, céramique, matériaux de construction",
            "Ouvriers de production non qualifiés de l'imprimerie, presse, édition",
            "Ouvriers de production non qualifiés de la transformation des viandes",
            "Ouvriers de production non qualifiés du textile et de la confection, de la tannerie-mégisserie et du travail du cuir",
            "Ouvriers de production non qualifiés du travail du bois et de l'ameublement",
            "Ouvriers de production non qualifiés travaillant par enlèvement de métal",
            "Ouvriers de production non qualifiés travaillant par formage de métal",
            "Ouvriers du tri, de l'emballage, de l'expédition, non qualifiés",
            "Ouvriers non qualifiés de l'électricité et de l'électronique",
            "Ouvriers non qualifiés de montage, contrôle en mécanique et travail des métaux",
            "Ouvriers non qualifiés des travaux publics de l'Etat et des collectivités locales",
            "Ouvriers non qualifiés des travaux publics et du travail du béton",
            "Ouvriers non qualifiés divers de type industriel"
        ],
        "Ouvriers qualifiés de la manutention, du magasinage et du transport": [
            "Autres agents et ouvriers qualifiés (sédentaires) des services d'exploitation des transports",
            "Capitaines et matelots timoniers de la navigation fluviale",
            "Conducteurs d'engin lourd de levage",
            "Conducteurs d'engin lourd de manoeuvre",
            "Conducteurs qualifiés d'engins de transport guidés (sauf remontées mécaniques)",
            "Conducteurs qualifiés de systèmes de remontées mécaniques",
            "Dockers",
            "Magasiniers qualifiés",
            "Matelots de la marine marchande",
            "Ouvriers qualifiés de la manutention, conducteurs de chariots élévateurs, caristes"
        ],
        "Ouvriers qualifiés de type artisanal": [
            "Bouchers (sauf industrie de la viande)",
            "Boulangers, pâtissiers (sauf activité industrielle)",
            "Carrossiers d'automobiles qualifiés",
            "Charcutiers (sauf industrie de la viande)",
            "Charpentiers en bois qualifiés",
            "Couvreurs qualifiés",
            "Cuisiniers et commis de cuisine",
            "Dépanneurs qualifiés en radiotélévision, électroménager, matériel électronique (salariés)",
            "Electriciens qualifiés de type artisanal (y.c. bâtiment)",
            "Electriciens, électroniciens qualifiés en maintenance entretien, réparation : automobile",
            "Electriciens, électroniciens qualifiés en maintenance, entretien : équipements non industriels",
            "Jardiniers",
            "Maçons qualifiés",
            "Menuisiers qualifiés du bâtiment",
            "Modeleurs (sauf modeleurs de métal), mouleurs-noyauteurs à la main, ouvriers qualifiés du travail du verre ou de la céramique à la main",
            "Monteurs qualifiés en agencement, isolation",
            "Mécaniciens qualifiés de maintenance, entretien : équipements non industriels",
            "Mécaniciens qualifiés en maintenance, entretien, réparation : automobile",
            "Métalliers, serruriers qualifiés",
            "Ouvriers d'art",
            "Ouvriers et techniciens des spectacles vivants et audiovisuels",
            "Ouvriers qualifiés d'entretien général des bâtiments",
            "Ouvriers qualifiés divers de type artisanal",
            "Ouvriers qualifiés du travail de la pierre",
            "Peintres et ouvriers qualifiés de pose de revêtements sur supports verticaux",
            "Plombiers et chauffagistes qualifiés",
            "Soliers moquetteurs et ouvriers qualifiés de pose de revêtements souples sur supports horizontaux",
            "Tailleurs et couturières qualifiés, ouvriers qualifiés du travail des étoffes (sauf fabrication de vêtements), ouvriers qualifiés de type artisanal du travail du cuir"
        ],
        "Ouvriers qualifiés de type industriel": [
            "Agents qualifiés de laboratoire (sauf chimie, santé)",
            "Autres monteurs câbleurs en électronique",
            "Autres mécaniciens ou ajusteurs qualifiés (ou spécialité non reconnue)",
            "Autres opérateurs et ouvriers qualifiés : métallurgie, production verrière, matériaux de construction",
            "Autres opérateurs et ouvriers qualifiés de la chimie (y.c. pharmacie) et de la plasturgie",
            "Autres opérateurs travaillant sur installations ou machines : industrie agroalimentaire (hors transformation des viandes)",
            "Autres ouvriers de production qualifiés ne travaillant pas sur machine : industrie agroalimentaire (hors transformation des viandes)",
            "Autres ouvriers qualifiés des travaux publics",
            "Bobiniers qualifiés",
            "Chaudronniers-tôliers industriels, opérateurs qualifiés du travail en forge, conducteurs qualifiés d'équipement de formage, traceurs qualifiés",
            "Chefs d'équipe du gros oeuvre et des travaux publics",
            "Conducteurs qualifiés d'engins de chantiers du bâtiment et des travaux publics",
            "Câbleurs qualifiés en électronique (prototype, unité, petite série)",
            "Electromécaniciens, électriciens qualifiés d'entretien : équipements industriels",
            "Mineurs de fond qualifiés et autres ouvriers qualifiés des industries d'extraction (carrières, pétrole, gaz...)",
            "Monteurs câbleurs qualifiés en électricité",
            "Monteurs qualifiés d'ensembles mécaniques travaillant en moyenne ou en grande série",
            "Monteurs qualifiés en structures métalliques",
            "Monteurs, metteurs au point très qualifiés d'ensembles mécaniques travaillant à l'unité ou en petite série",
            "Mécaniciens qualifiés de maintenance, entretien : équipements industriels",
            "Opérateurs de la transformation des viandes",
            "Opérateurs et ouvriers qualifiés des industries lourdes du bois et de la fabrication du papier-carton",
            "Opérateurs qualifiés d'usinage des métaux sur autres machines (sauf moulistes)",
            "Opérateurs qualifiés d'usinage des métaux travaillant à l'unité ou en petite série, moulistes qualifiés",
            "Opérateurs qualifiés du textile et de la mégisserie",
            "Opérateurs qualifiés sur machine de soudage",
            "Opérateurs qualifiés sur machines automatiques en production électrique ou électronique",
            "Ouvriers de la composition et de l'impression, ouvriers qualifiés de la brochure, de la reliure et du façonnage du papier-carton",
            "Ouvriers de la photogravure et des laboratoires photographiques et cinématographiques",
            "Ouvriers des travaux publics en installations électriques et de télécommunications",
            "Ouvriers qualifiés de contrôle et d'essais en mécanique",
            "Ouvriers qualifiés de l'assainissement et du traitement des déchets",
            "Ouvriers qualifiés de la coupe des vêtements et de l'habillement, autres opérateurs de confection qualifiés",
            "Ouvriers qualifiés de scierie, de la menuiserie industrielle et de l'ameublement",
            "Ouvriers qualifiés des autres industries (eau, gaz, énergie, chauffage)",
            "Ouvriers qualifiés des traitements thermiques et de surface sur métaux",
            "Ouvriers qualifiés des travaux publics (salariés de l'Etat et des collectivités locales)",
            "Ouvriers qualifiés divers de type industriel",
            "Ouvriers qualifiés du travail du béton",
            "Ouvriers qualifiés du travail industriel du cuir",
            "Ouvriers qualifiés et agents qualifiés de laboratoire : agroalimentaire, chimie, biologie, pharmacie",
            "Pilotes d'installation lourde des industries de transformation : agroalimentaire, chimie, plasturgie, énergie",
            "Pilotes d'installation lourde des industries de transformation : métallurgie, production verrière, matériaux de construction",
            "Plateformistes, contrôleurs qualifiés de matériel électrique ou électronique",
            "Régleurs qualifiés d'équipements de fabrication (hors travail des métaux et mécanique)",
            "Régleurs qualifiés d'équipements de fabrication (travail des métaux, mécanique)",
            "Soudeurs manuels",
            "Tuyauteurs industriels qualifiés"
        ],
        "Personnels des services directs aux particuliers": [
            "Aides de cuisine, apprentis de cuisine et employés polyvalents de la restauration",
            "Aides à domicile, aides ménagères, travailleuses familiales",
            "Assistantes maternelles, gardiennes d'enfants, familles d'accueil",
            "Coiffeurs salariés",
            "Concierges, gardiens d'immeubles",
            "Employés d'étage et employés polyvalents de l'hôtellerie",
            "Employés de l'hôtellerie : réception et hall",
            "Employés de maison et personnels de ménage chez des particuliers",
            "Employés des services divers",
            "Manucures, esthéticiens ",
            "Serveurs, commis de restaurant, garçons non qualifiés",
            "Serveurs, commis de restaurant, garçons qualifiés",
            "Vendeurs en gros de biens d'équipement, biens intermédiaires"
        ],
        "Policiers, militaires et agents de surveillance": [
            "Agents civils de sécurité et de surveillance",
            "Agents de police de l'Etat",
            "Agents de surveillance du patrimoine et des administrations",
            "Agents des polices municipales",
            "Agents techniques forestiers, gardes des espaces naturels",
            "Convoyeurs de fonds, gardes du corps, enquêteurs privés et métiers assimilés (salariés)",
            "Gendarmes (de grade inférieur à adjudant)",
            "Hommes du rang (sauf pompiers militaires)",
            "Pompiers (y.c. pompiers militaires)",
            "Sergents et sous-officiers de grade équivalent des Armées (sauf pompiers militaires)",
            "Surveillants de l'administration pénitentiaire"
        ],
        "Professeurs des écoles, instituteurs et professions assimilées": [
            "Conseillers principaux d'éducation",
            "Formateurs et animateurs de formation continue",
            "Instituteurs",
            "Maîtres auxiliaires et professeurs contractuels de l'enseignement secondaire",
            "Moniteurs d'école de conduite",
            "Moniteurs et éducateurs sportifs, sportifs professionnels",
            "Professeurs d'enseignement général des collèges",
            "Professeurs de lycée professionnel",
            "Professeurs des écoles",
            "Sous-bibliothécaires, cadres intermédiaires du patrimoine",
            "Surveillants et aides-éducateurs des établissements d'enseignement"
        ],
        "Professeurs, professions scientifiques": [
            "Allocataires de la recherche publique",
            "Chefs d'établissement de l'enseignement secondaire et inspecteurs",
            "Directeurs et chargés de recherche de la recherche publique",
            "Ingénieurs d'étude et de recherche de la recherche publique",
            "Internes en médecine, odontologie et pharmacie",
            "Médecins hospitaliers sans activité libérale",
            "Médecins salariés non hospitaliers",
            "Personnel enseignant temporaire de l'enseignement supérieur",
            "Pharmaciens salariés",
            "Professeurs agrégés et certifiés de l'enseignement secondaire",
            "Professeurs agrégés et certifiés en fonction dans l'enseignement supérieur",
            "Professeurs et maîtres de conférences",
            "Psychologues spécialistes de l'orientation scolaire et professionnelle"
        ],
        "Professions de l'information, des arts et des spectacles": [
            "Artistes de la danse",
            "Artistes de la musique et du chant",
            "Artistes dramatiques",
            "Artistes du cirque et des spectacles divers",
            "Artistes plasticiens",
            "Auteurs littéraires, scénaristes, dialoguistes",
            "Bibliothécaires, archivistes, conservateurs et autres cadres du patrimoine",
            "Cadres artistiques et technico-artistiques de la réalisation de l'audiovisuel et des spectacles",
            "Directeurs de journaux, administrateurs de presse, directeurs d'éditions (littéraire, musicale, audiovisuelle et multimédia)",
            "Directeurs, responsables de programmation et de production de l'audiovisuel et des spectacles",
            "Journalistes (y c. rédacteurs en chef)",
            "Professeurs d'art (hors établissements scolaires)"
        ],
        "Professions intermédiaires administratives de la fonction publique": [
            "Adjudants-chefs, adjudants et sous-officiers de rang supérieur de l'Armée et de la Gendarmerie",
            "Autres personnels administratifs de catégorie B de l'Etat (hors Enseignement, Patrimoine, Impôts, Trésor, Douanes)",
            "Contrôleurs des Impôts, du Trésor, des Douanes et assimilés",
            "Ingénieurs du contrôle de la navigation aérienne",
            "Inspecteurs et officiers de police",
            "Professions intermédiaires administratives de France Télécom (statut public)",
            "Professions intermédiaires administratives des collectivités locales",
            "Professions intermédiaires administratives des hôpitaux",
            "Professions intermédiaires de la Poste"
        ],
        "Professions intermédiaires administratives et commerciales des entreprises": [
            "Acheteurs non classés cadres, aides-acheteurs",
            "Animateurs commerciaux des magasins de vente, marchandiseurs (non cadres)",
            "Assistants de la publicité, des relations publiques",
            "Assistants techniques de la réalisation des spectacles vivants et audiovisuels salariés",
            "Autres professions intermédiaires commerciales (sauf techniciens des forces de vente)",
            "Chargés de clientèle bancaire",
            "Chefs de petites surfaces de vente",
            "Concepteurs et assistants techniques des arts graphiques, de la mode et de la décoration salariés",
            "Interprètes, traducteurs",
            "Maîtrise de l'exploitation des magasins de vente",
            "Maîtrise de l'hébergement : hall et étages",
            "Maîtrise de restauration : salle et service",
            "Maîtrise et techniciens administratifs des autres services administratifs",
            "Maîtrise et techniciens administratifs des services juridiques ou du personnel",
            "Maîtrise et techniciens des services financiers ou comptables",
            "Photographes",
            "Professions intermédiaires techniques des organismes de sécurité sociale",
            "Professions intermédiaires techniques et commerciales des assurances",
            "Responsables commerciaux et administratifs des transports de marchandises (non cadres)",
            "Responsables commerciaux et administratifs des transports de voyageurs et du tourisme (non cadres)",
            "Responsables d'exploitation des transports de voyageurs et de marchandises (non cadres)",
            "Secrétaires de direction, assistants de direction (non cadres)",
            "Secrétaires de niveau supérieur (non cadres, hors secrétaires de direction)",
            "Techniciens commerciaux et technico-commerciaux, représentants auprès de particuliers (hors banque, assurance, informatique)",
            "Techniciens commerciaux et technico-commerciaux, représentants en biens d'équipement, en biens intermédiaires, commerce interindustriel (hors informatique)",
            "Techniciens commerciaux et technico-commerciaux, représentants en biens de consommation auprès d'entreprises",
            "Techniciens commerciaux et technico-commerciaux, représentants en informatique",
            "Techniciens commerciaux et technico-commerciaux, représentants en services auprès d'entreprises ou de professionnels (hors banque, assurance, informatique)",
            "Techniciens des opérations bancaires"
        ],
        "Professions intermédiaires de la santé et du travail social": [
            "Animateurs socioculturels et de loisirs",
            "Assistants de service social",
            "Autres spécialistes de l'appareillage médical salariés",
            "Autres spécialistes de la rééducation, salariés",
            "Cadres de l'intervention socio-éducative",
            "Cadres infirmiers et assimilés",
            "Conseillers en économie sociale familiale",
            "Directeurs de centres socioculturels et de loisirs",
            "Educateurs de jeunes enfants",
            "Educateurs spécialisés",
            "Educateurs techniques spécialisés, moniteurs d'atelier",
            "Infirmiers en soins généraux, salariés",
            "Infirmiers psychiatriques",
            "Infirmiers spécialisés (autres qu'infirmiers psychiatriques et puéricultrices)",
            "Masseurs-kinésithérapeutes rééducateurs, salariés",
            "Moniteurs éducateurs",
            "Opticiens lunetiers et audioprothésistes salariés",
            "Préparateurs en pharmacie",
            "Puéricultrices",
            "Sages-femmes salariées",
            "Techniciens médicaux"
        ],
        "Professions libérales (exercées sous statut de salarié)": [
            "Avocats",
            "Chirurgiens dentistes",
            "Personnels de direction de la fonction publique (Etat, collectivités locales, hôpitaux)",
            "Psychologues, psychanalystes, psychothérapeutes (non médecins)",
            "Vétérinaires"
        ],
        "Techniciens (sauf techniciens tertiaires)": [
            "Assistants techniques, techniciens de l'imprimerie et de l'édition",
            "Dessinateurs en bâtiment, travaux publics",
            "Dessinateurs en construction mécanique et travail des métaux",
            "Dessinateurs en électricité, électromécanique et électronique",
            "Experts salariés de niveau technicien, techniciens divers",
            "Géomètres, topographes",
            "Métreurs et techniciens divers du bâtiment et des travaux publics",
            "Techniciens d'exploitation et de contrôle de la production en agriculture, eaux et forêt",
            "Techniciens d'installation et de maintenance des équipements industriels (électriques, électromécaniques, mécaniques, hors informatique)",
            "Techniciens d'installation et de maintenance des équipements non industriels (hors informatique et télécommunications)",
            "Techniciens d'installation, de maintenance, support et services aux utilisateurs en informatique",
            "Techniciens d'étude et de conseil en agriculture, eaux et forêt",
            "Techniciens d'étude et de développement en informatique",
            "Techniciens de fabrication et de contrôle-qualité en construction mécanique et travail des métaux",
            "Techniciens de fabrication et de contrôle-qualité en électricité, électromécanique et électronique",
            "Techniciens de l'environnement et du traitement des pollutions",
            "Techniciens de l'industrie des matériaux souples, de l'ameublement et du bois",
            "Techniciens de la logistique, du planning et de l'ordonnancement",
            "Techniciens de production et de contrôle-qualité des industries de transformation",
            "Techniciens de production, d'exploitation en informatique",
            "Techniciens de recherche-développement et des méthodes de fabrication en construction mécanique et travail des métaux",
            "Techniciens de recherche-développement et des méthodes de fabrication en électricité, électromécanique et électronique",
            "Techniciens de recherche-développement et des méthodes de production des industries de transformation",
            "Techniciens des laboratoires de recherche publique ou de l'enseignement",
            "Techniciens des travaux publics de l'Etat et des collectivités locales",
            "Techniciens des télécommunications et de l'informatique des réseaux"
        ]
    };
}