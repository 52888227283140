import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import './Searchbar.scss';

export default function Searchbar(props: { job?: string, location?: string, handleNewSearch?: any; }) {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'searchbar' });
  const [job, setJob] = React.useState(props.job);
  const [location, setLocation] = React.useState(props.location);

  const jobInputRef = useRef<HTMLInputElement>(null);
  const locationInputRef = useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    setJob(props.job);
    setLocation(props.location);
  }, [props.job, props.location]);

  const handleJobDivClick = () => {
    jobInputRef?.current?.focus();
  };

  const handleLocationDivClick = () => {
    locationInputRef?.current?.focus();
  };

  const handleJob = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setJob(event.target.value);
  };

  const handleLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setLocation(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      submitSearch();
    }
  };

  const submitSearch = () => {
    if (job?.length || location?.length) {
      navigate(`/search?j=${job ?? ''}&l=${location ?? ''}`);

      if (props.handleNewSearch) {
        props.handleNewSearch({ job, location });
      }
    }
  };

  return (
    <div className="searchbar">
      <div className="searchbar-job-input" onClick={handleJobDivClick}>
        <span>{t('search')}</span>
        <input
          type="text"
          ref={jobInputRef}
          onChange={(e) => handleJob(e)}
          value={job ? job : ''}
          onKeyDown={handleKeyDown}
          placeholder={t('searchPlaceholder')}
        />
      </div>
      <div className="searchbar-location-input" onClick={handleLocationDivClick}>
        <span>{t('location')}</span>
        <input
          type="text"
          ref={locationInputRef}
          onChange={(e) => handleLocation(e)}
          value={location ? location : ''}
          onKeyDown={handleKeyDown}
          placeholder={t('locationPlaceholder')}
        />
      </div>
      <div className="searchbar-btn">
        <button onClick={() => submitSearch()}><FontAwesomeIcon icon="search" /></button>
      </div>
    </div>
  );
}