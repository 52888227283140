import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import './Login.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isConnected, login } from "../../../services/auth.service";
import { toast } from 'react-toastify';

export default function Login() {
  const { t } = useTranslation('translation', { keyPrefix: 'login' });
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  useEffect(() => {
    window.scrollTo(0, 0);

    if (isConnected()) {
      navigate('/dashboard');
    }
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const loginResult = await login(email, password, true);

    if (loginResult?.success) {
      toast.success(t('success'), { autoClose: 2000 });
      navigate('/dashboard');
    } else {
      console.error('Login error.');
      toast.error(t('error'));
    }
  };

  return (
    <div className="container">
      <div className="login" >
        <h1>{t('title')}</h1>

        {/* Form */}
        <div className="login-form">
          <div className="login-form-item">
            <label>{t('email')}</label>
            <input required type="email" onChange={e => setEmail(e.target.value)} />
          </div>
          <div className="login-form-item">
            <label>{t('password')}</label>
            <input required type="password" onChange={e => setPassword(e.target.value)} />
          </div>

          <button onClick={handleSubmit}>{t('continue')} <FontAwesomeIcon icon="arrow-right-to-bracket" /></button>
        </div>

        <div className="register-link">
          <Link to="/register">{t('register')}</Link>
        </div>
      </div>
    </div>
  );
}