import { Constants } from "../utils/constants.utils";
import { getToken, logout } from "./auth.service";

export async function getCurrentUser(navigate) {
  try {
    const response = await fetch(
      `${Constants.API_URL}/api/v1/users/current`,
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: `bearer ${getToken()}`,
        },
        method: 'GET',
      }
    );

    if (response.status === 200) {
      return response.json()
    } else if (response.status === 401) {
      console.log('🚀 > logout...')
      logout(navigate, '/login', true);
    } else {
      let text = await response.text();
      return { error: JSON.parse(text), status: response.status };
    }
  } catch (error) {
    console.log('🚀 > getCurrentUser > error:', error)
  }
}

export async function updateCurrentUser(body, navigate) {
  try {
    const response = await fetch(
      `${Constants.API_URL}/api/v1/users/current`,
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: `bearer ${getToken()}`,
        },
        method: 'PATCH',
        body: JSON.stringify(body)
      }
    );

    if (response.status === 200) {
      return response.json()
    } else if (response.status === 401) {
      logout(navigate, '/login', true);
    } else {
      let text = await response.text();
      return { error: JSON.parse(text), status: response.status };
    }
  } catch (error) {
    console.log('🚀 > updateCurrentUser > error:', error);
  }
}

export async function updateCurrentPassword(body, navigate) {
  try {
    const response = await fetch(
      `${Constants.API_URL}/api/v1/users/current/password`,
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: `bearer ${getToken()}`,
        },
        method: 'PATCH',
        body: JSON.stringify(body)
      }
    );

    if (response.status === 200) {
      return response.json();
    } else if (response.status === 401) {
      logout(navigate, '/login', true);
    } else {
      let text = await response.text();
      return { error: JSON.parse(text), status: response.status };
    }
  } catch (error) {
    console.log('🚀 > updateCurrentPassword > error:', error);
  }
}