import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import './Search.scss';
import { ContractTypes } from "../../utils/interfaces/contract-types.interface";
import Searchbar from "../../components/searchbar/Searchbar";
import { searchOffers } from "../../services/searches.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Search() {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'search' });

  const [offers, setOffers] = React.useState([]);
  const [filteredOffers, setFilteredOffers] = React.useState([]);
  const [nbOffers, setNbOffers] = React.useState(0);
  const [contractTypes, setContractTypes] = React.useState({} as ContractTypes);
  const [loading, setLoading] = React.useState(false);
  const [job, setJob] = React.useState('');
  const [location, setLocation] = React.useState('');
  const [availableBenefits, setAvailableBenefits] = React.useState([] as string[]);
  const [selectedBenefits, setSelectedBenefits] = React.useState([] as string[]);

  React.useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const jobParam = queryParameters.get("j");

    if (jobParam?.length) {
      setJob(jobParam);
    }

    const locationParam = queryParameters.get("l");

    if (locationParam?.length) {
      setLocation(locationParam);
    }

    // Search results if job or location
    if (jobParam || locationParam) {
      search(jobParam || '', locationParam || '');
    }
  }, []);

  /**
  * Search query
  * @param {*} query
  */
  const search = async (job: string, location: string) => {
    setLoading(true);

    console.log('🚀 > search > job:', job);
    console.log('🚀 > search > location:', location);

    // Search results
    const searchResult = await searchOffers(job || location, 40);
    console.log('🚀 > search > searchResult:', searchResult);

    // Extract all contract types & benefits
    if (searchResult?.length) {
      const _availableBenefits: any[] = [];
      const _contractTypes: any = {};

      // Search benefits in search result
      for (const item of searchResult) {
        _contractTypes[item.contractType] = true;

        // * Split item.benefits (string in search) to get only available benefits
        if (item?.benefits?.length) {
          const cleanedBenefits = []; // Save cleander benefits to display it
          const split = item.benefits.split('|');

          for (const benefit of split) {
            cleanedBenefits.push(benefit.trim());

            if (!_availableBenefits.includes(benefit.trim())) {
              _availableBenefits.push(benefit.trim());
            }
          }

          item.benefits = cleanedBenefits;
        }
      }

      setAvailableBenefits(_availableBenefits);
      setContractTypes(_contractTypes);
      setOffers(searchResult); // ! Original array, don't touch it
      setFilteredOffers(searchResult); // Copy of original array to filter contracts & benefits
    }

    setNbOffers(searchResult.length);
    setLoading(false);
  };

  // TODO filtrage indépendant des avantages pour le moment
  /**
   * Filter on contract type
   * @param event
   */
  const handleContractTypes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const tempContracts = { ...contractTypes, [event.target.name]: event.target.checked };
    setContractTypes(tempContracts);

    // Filter offers with all contract types equal to true
    const _filteredOffers: any = [];

    // * Use tempsContracts to avoid the slow states refresh
    for (const type of Object.keys(tempContracts)) {
      if (tempContracts[type as keyof ContractTypes] === true) {
        const items = offers.filter((o: any) => o.contractType === type);

        if (items?.length) {
          _filteredOffers.push(...items);
        }
      }
    }

    setFilteredOffers(_filteredOffers);
    setNbOffers(_filteredOffers.length);
    setLoading(false);
  };

  // TODO filtrage indépendant des types de contrat pour le moment
  /**
   * Filter on benefits
   * @param value
   */
  const handleSelectBenefits = (value: string) => {
    setLoading(true);

    let tempSelectedBenefits = selectedBenefits;

    if (tempSelectedBenefits.includes(value)) {
      tempSelectedBenefits = tempSelectedBenefits.filter(b => b !== value);
    } else {
      tempSelectedBenefits = [...tempSelectedBenefits, value];
    }

    setSelectedBenefits(tempSelectedBenefits);

    // Filter offers with all contract types equal to true
    const _filteredOffers: any = [];

    if (tempSelectedBenefits.length) {
      for (const benefit of tempSelectedBenefits) {
        const items = offers.filter((o: any) => o.benefits.includes(benefit));

        if (items?.length) {
          _filteredOffers.push(...items);
        }
      }
    }


    setFilteredOffers(tempSelectedBenefits.length ? _filteredOffers : offers);
    setNbOffers(tempSelectedBenefits.length ? _filteredOffers.length : offers.length);
    setLoading(false);
  };

  const goToJob = (jobId: string) => {
    navigate(`/jobs?id=${jobId}`);
  };

  return (
    <div className="container">
      <div className="search" >
        {/* Search bar */}
        <Searchbar job={job} location={location} handleNewSearch={(data: any) => search(data?.job, data?.location)} />

        {/* Results */}
        {!loading ?
          <div className="search-results">
            {/* Filters */}
            <div className="search-results-content">
              <div className="search-results-content-filters">
                <span><b>{nbOffers}</b> {t('offersFound')}</span>

                <div>
                  <h2>{t('contractType')}</h2>

                  {Object.keys(contractTypes).map((key: string, index) => {
                    return (
                      <label className="contract-type-checkbox" key={index}>
                        <input
                          name={key}
                          type="checkbox"
                          checked={contractTypes[key as keyof ContractTypes]}
                          onChange={handleContractTypes}
                        />
                        <span>{t(key)}</span>
                      </label>
                    );
                  })}
                </div>

                <div>
                  <h2>{t('benefits')}</h2>

                  <div className="benefits-list">
                    {availableBenefits.map((item, key) => {
                      return (
                        <span
                          key={key}
                          className={selectedBenefits.includes(item) ? 'selected' : ''}
                          onClick={() => handleSelectBenefits(item)}
                        >{item}</span>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* Offers */}
              <div className="search-results-content-list">
                {filteredOffers.map((item: any, index) => {
                  return (
                    <div key={index} className="list-item">
                      <div className="list-item-description">
                        <h2>{item.title}</h2>
                        <span className="list-item-type">{t(item.contractType)}</span>
                        <span className="list-item-location">{item.location} <FontAwesomeIcon icon="map-pin" /></span>

                        {/* Tags */}
                        {item?.benefits?.length ?
                          <div className="list-item-benefits">
                            {item.benefits.map((item: string, key: number) => {
                              return <span key={key}>{item}</span>;
                            })}
                          </div> : ''
                        }
                      </div>

                      <div className="list-item-action">
                        <button onClick={() => goToJob(item.id)}>{t('consult')}</button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div> :
          // Loading
          <div>
            <div className='loader-container'>
              <div className="loader"></div>
            </div>
          </div>
        }
      </div>
    </div>
  );
}