import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import './Home.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "../../components/card/Card";
import Searchbar from "../../components/searchbar/Searchbar";
import { getAllCompanies, getAllOffers, getOffersCount } from "../../services/public.service";

export default function Home() {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'home' });

  const [companies, setCompanies] = React.useState<any[]>([]);
  const [lastOffers, setLastOffers] = React.useState<any[]>([]);
  const [offersCount, setOffersCount] = React.useState(0);
  const [companiesLoading, setCompaniesLoading] = React.useState(false);
  const [lastOffersLoading, setLastOffersLoading] = React.useState(false);

  React.useEffect(() => {
    loadOffersCount();
    loadCompanies();
    loadLastOffers();
  }, []);

  /**
   * Load total offers count
   */
  const loadOffersCount = async () => {
    const result = await getOffersCount();

    if (result > 0) {
      setOffersCount(result);
    }
  };

  /**
   * Load 4 companies with the most offers
   */
  const loadCompanies = async () => {
    setCompaniesLoading(true);

    const result = await getAllCompanies(4, true);

    if (result?.length) {
      const _companies = [];

      for (const item of result) {
        if (item?.offersCounter > 0) {
          _companies.push(item);
        }
      }

      setCompanies(_companies);
    }

    setCompaniesLoading(false);
  };

  /**
   * Load last 4 offers
   */
  const loadLastOffers = async () => {
    setLastOffersLoading(true);

    const result = await getAllOffers(4, true);

    if (result?.length) {
      setLastOffers(result);
    }

    setLastOffersLoading(false);
  };

  return (
    <div className="home">
      <div className="container" >
        {/* Infos */}
        <div className="home-infos">
          <span>{offersCount ? <b>{offersCount}</b> : '...'} {t('availableOffers')}</span>
          <button onClick={() => navigate('/search')}>{t('searchOffer')}</button>
        </div>

        {/* Search bar */}
        <Searchbar />

        {/* Companies */}
        <div className="home-companies">
          <h1>{t('companies')}</h1>

          {!companiesLoading ? <div className="card-list">
            {companies?.length ? companies.map((company, index) => {
              return (
                <Card key={index} data={company} type='company' />
              );
            }) : ''}
          </div> :
            // Loading
            <div>
              <div className='loader-container'>
                <div className="loader"></div>
              </div>
            </div>
          }
        </div>

        {/* Last jobs */}
        <div className="home-last-jobs">
          <h1>{t('lastOffers')}</h1>

          {!lastOffersLoading ? <div className="card-list">
            {lastOffers?.length ? lastOffers.map((offer, index) => {
              return (
                <Card key={index} data={offer} type='job' />
              );
            }) : ''}
          </div> :
            // Loading
            <div>
              <div className='loader-container'>
                <div className="loader"></div>
              </div>
            </div>
          }
        </div>
      </div>

      {/* Footer */}
      <div className="home-footer">
        <div className="footer-container">
          <div className="home-footer-left">
            <span>{t('offers')}</span>
            <span>{t('legals')}</span>
            <span>{t('help')}</span>
          </div>
          <div className="home-footer-right">
            <span>{t('followUs')} </span>
            <div>
              <a href="https://linkedin.com" target="blank"><FontAwesomeIcon icon={["fab", "linkedin-in"]} /></a>
              <a href="https://twitter.com" target="blank"><FontAwesomeIcon icon={["fab", "twitter"]} /></a>
              <a href="https://instagram.com" target="blank"><FontAwesomeIcon icon={["fab", "instagram"]} /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}