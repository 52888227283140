import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import Navbar from './components/navbar/Navbar';
import Home from './pages/home/Home';
import Search from './pages/search/Search';
import Login from './pages/account/login/Login';
import Register from './pages/account/register/Register';
import Dashboard from './pages/account/dashboard/Dashboard';
import JobCreation from './pages/job/creation/JobCreation';
import Job from './pages/job/Job';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Company from './pages/company/Company';

library.add(fas, far, fab);

export default function App() {
  return (
    <Router>
      <div className="app">
        <Navbar />

        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/search" element={<Search />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/register" element={<Register />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/jobs/post" element={<JobCreation />}></Route>
          <Route path="/jobs" element={<Job />}></Route>
          <Route path="/companies" element={<Company />}></Route>
        </Routes>
      </div>

      <ToastContainer />
    </Router>
  );
}
