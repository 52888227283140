export class Constants {
  // static API_URL = 'http://localhost:3001'
  static API_URL = 'https://job-api-production-7bda.up.railway.app'
  // static IMAGES_API_URL = 'https://ik.imagekit.io/oa82im6fg'
  // static IMAGES_API_PUBLIC_KEY = 'public_U9XNzkyxQA6HVKXbvXIhIcZjtes='

  static emailRegex = RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
  static phoneRegex = RegExp(/^\+(?:[0-9] ?){6,14}[0-9]$/);

  static candidateRegisterRules = {
    firstName: {
      maxLength: 100,
      required: true,
    },
    lastName: {
      maxLength: 100,
      required: true,
    },
    email: {
      maxLength: 250,
      required: true,
      regex: 'email',
    },
    confirmEmail: {
      maxLength: 250,
      required: true,
      regex: 'email',
    },
    password: {
      maxLength: 50,
      required: true,
    },
    confirmPassword: {
      maxLength: 50,
      required: true,
    },
  };

  static companyRegisterRules = {
    name: {
      maxLength: 250,
      required: true,
    },
    siret: {
      maxLength: 20,
      required: false,
    },
    address: {
      maxLength: 250,
      required: true,
    },
    zipCode: {
      maxLength: 10,
      required: true,
    },
    city: {
      maxLength: 100,
      required: true,
    },
    country: {
      maxLength: 50,
      required: true,
    },
    phone: {
      maxLength: 20,
      required: true,
      regex: 'phone',
    },
    firstName: {
      maxLength: 100,
      required: true,
    },
    lastName: {
      maxLength: 100,
      required: true,
    },
    email: {
      maxLength: 250,
      required: true,
      regex: 'email',
    },
    confirmEmail: {
      maxLength: 250,
      required: true,
      regex: 'email',
    },
    password: {
      maxLength: 50,
      required: true,
    },
    confirmPassword: {
      maxLength: 50,
      required: true,
    },
  };

  static editCompanyInfosRules = {
    name: {
      maxLength: 250,
      required: true,
    },
    siret: {
      maxLength: 20,
      required: false,
    },
    address: {
      maxLength: 250,
      required: true,
    },
    zipCode: {
      maxLength: 10,
      required: true,
    },
    city: {
      maxLength: 100,
      required: true,
    },
    country: {
      maxLength: 50,
      required: true,
    },
    phone: {
      maxLength: 20,
      required: true,
      regex: 'phone',
    },
  };

  static editUserInfosRules = {
    firstName: {
      maxLength: 100,
      required: true,
    },
    lastName: {
      maxLength: 100,
      required: true,
    },
    email: {
      maxLength: 250,
      required: true,
      regex: 'email',
    },
  };

  static editPasswordRules = {
    oldPassword: {
      maxLength: 50,
      required: true,
    },
    newPassword: {
      maxLength: 50,
      required: true,
    },
  };

  static jobCreationRules = {
    title: {
      maxLength: 250,
      required: true,
    },
    description: {
      maxLength: 5000,
      required: true,
    },
    contractType: {
      required: true
    },
    activitySector: {
      required: true,
    },
    category: {
      required: true
    },
    location: {
      maxLength: 250,
      required: true,
    },
    contact: {
      maxLength: 500,
      required: true,
    }
  };

  static contractTypes = ["openEndedContract", "fixedTermContract", "interim", "workStudy", "internship"];

  static defaultDescription = `
    <h2>Description du poste</h2>
    <h2>À propos de l'entreprise</h2>
    <h2>Profil recherché</h2>
    <br>
    <h3>Horaires</h3>
    <h3>Rémunération</h3>
  `;

  static editorModules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link'],
    ],
  };

  static editorFormats = ['header', 'bold', 'italic', 'underline', 'blockquote', 'list', 'bullet', 'indent', 'link'];

  public static checkEmail(value: string) {
    return this.emailRegex.test(value);
  }

  public static checkPhone(value: string) {
    return this.phoneRegex.test(value);
  }
}