import { Constants } from "../utils/constants.utils";

/**
 * Search engine on offers
 * @param {*} query search text
 * @param {*} nb number of results
 * @returns
 */
export async function searchOffers(query: string, nb: number) {
  try {
    const response = await fetch(
      `${Constants.API_URL}/api/v1/searches?q=${query}&n=${nb}`,
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
        method: 'GET',
      }
    );

    if (response.status === 200) {
      return response.json()
    } else {
      return { error: JSON.parse(await response.text()), status: response.status };
    }
  } catch (error) {
    console.log('🚀 > searchOffers > error:', error)
  }
}