import { Constants } from "../utils/constants.utils";
import { getToken, logout } from "./auth.service";

/**
 *
 * @param navigate
 * @returns
 */
export async function getCurrentCompanyOffers(navigate: any) {
  try {
    const response = await fetch(
      `${Constants.API_URL}/api/v1/companies/current/offers`,
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: `bearer ${getToken()}`,
        },
        method: 'GET',
      }
    );

    if (response.status === 200) {
      return response.json();
    } else if (response.status === 401) {
      logout(navigate, '/login', true);
    } else {
      const text = await response.text();
      return { error: JSON.parse(text), status: response.status };
    }
  } catch (error) {
    console.log('🚀 > getCurrentCompanyOffers > error:', error)
  }
}

/**
 *
 * @param data
 * @param navigate
 * @returns
 */
export async function createOffer(data: any, navigate: any) {
  try {
    const response = await fetch(
      `${Constants.API_URL}/api/v1/companies/current/offers`,
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: `bearer ${getToken()}`,
        },
        method: 'POST',
        body: JSON.stringify(data)
      }
    );

    if (response.status === 201) {
      return response.json();
    } else if (response.status === 401) {
      logout(navigate, '/login', true);
    } else {
      const text = await response.text();
      return { error: JSON.parse(text), status: response.status };
    }
  } catch (error) {
    console.log('🚀 > createOffer > error:', error)
  }
}

/**
 *
 * @param id
 * @param data
 * @param navigate
 * @returns
 */
export async function updateOffer(id: string, data: any, navigate: any) {
  try {
    const response = await fetch(
      `${Constants.API_URL}/api/v1/companies/current/offers/${id}`,
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: `bearer ${getToken()}`,
        },
        method: 'PATCH',
        body: JSON.stringify(data)
      }
    );

    if (response.status === 200) {
      return response.json();
    } else if (response.status === 401) {
      logout(navigate, '/login', true);
    } else {
      const text = await response.text();
      return { error: JSON.parse(text), status: response.status };
    }
  } catch (error) {
    console.log('🚀 > updateOffer > error:', error);
  }
}

/**
 *
 * @param id
 * @param navigate
 * @returns
 */
export async function deleteOffer(id: string, navigate: any) {
  try {
    const response = await fetch(
      `${Constants.API_URL}/api/v1/companies/current/offers/${id}`,
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: `bearer ${getToken()}`,
        },
        method: 'DELETE',
      }
    );

    if (response.status === 200) {
      return response.json();
    } else if (response.status === 401) {
      logout(navigate, '/login', true);
    } else {
      const text = await response.text();
      return { error: JSON.parse(text), status: response.status };
    }
  } catch (error) {
    console.log('🚀 > deleteOffer > error:', error)
  }
}