import React from "react";
import { useTranslation } from "react-i18next";
import './Job.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCompanyById, getOfferById } from "../../services/public.service";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

export default function Job() {
  const { t } = useTranslation('translation', { keyPrefix: 'job' });
  const navigate = useNavigate();

  const [job, setJob] = React.useState<any>();
  const [company, setCompany] = React.useState<any>();
  const [loading, setLoading] = React.useState(false);
  const [contactyMode, setContactMode] = React.useState('');

  React.useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const jobId = queryParameters.get("id");

    if (jobId?.length) {
      loadJob(jobId ?? '');
    }
  }, []);

  const loadJob = async (jobId: string) => {
    setLoading(true);

    const jobData = await getOfferById(jobId);

    if (jobData && Object.keys(jobData).length) {
      let companyData = null;

      // Load company to get her name and benefits
      try {
        companyData = await getCompanyById(jobData.companyId);
        console.log('🚀 > loadJob > companyData:', companyData);
      } catch (error) {
        console.log('🚀 > loadJob > error in getCompanyById:', error);
      }

      if (companyData?._id) {
        setCompany(companyData);
      } else {
        toast.error(t('notFound'));
        navigate('/');
      }

      if (jobData.contact && (jobData.contact.includes('http://') || jobData.contact.includes('https://'))) {
        setContactMode('link');
      } else {
        if (!jobData.contact) jobData.contact = "contact@monjob.store"; // ! For debug
        setContactMode('email');
      }

      setJob(jobData);
    } else {
      toast.error(t('notFound'));
      navigate('/');
    }

    setLoading(false);
  };

  const copyEmail = (email: string) => {
    // TODO toast + copy
  };


  return (
    <div className="container">
      {!loading ?
        <div>
          {job ? <div className="job" >
            {/* Title */}
            <div className="job-header">
              <h1>{job.title}</h1>

              {contactyMode === 'email' ?
                <a
                  href={"mailto:" + job.contact}
                  onClick={() => copyEmail(job.contact)}>
                  {t('apply')} <FontAwesomeIcon icon="paper-plane" /></a> :
                <a href={job.contact} target="blank">{t('apply')} <FontAwesomeIcon icon="paper-plane" /></a>
              }
            </div>

            {/* Content */}
            <div className="job-content">
              {company ? <span
                className="job-content-company"
                onClick={() => navigate(`/companies?id=${company._id}`)}>
                {company.name}</span> : ''}

              <div className="job-content-infos">
                <span className="job-content-infos-contract-type">{t(job.contractType)}</span>
                <span>{job.activitySector} / {job.category}</span>
                <span><FontAwesomeIcon icon="map-pin" /> {job.location}</span>
              </div>

              <div className="job-content-editor">
                <ReactQuill
                  theme="bubble"
                  value={job.description}
                  readOnly={true}
                  modules={
                    { 'toolbar': null }
                  }
                />
              </div>

              {company?.benefits?.length ? <span className="job-content-benefits-title">{"Avantages de l'entreprise"}</span> : ''}
              {company?.benefits?.length ?
                <div className="job-content-benefits">
                  {company.benefits.map((item: any, index: number) => {
                    return (
                      <div className="job-content-benefits-item" key={index}>{item.name}</div>
                    );
                  })}
                </div> : ''}

              {job?.benefits?.length ? <span className="job-content-benefits-title">{"Avantages de l'offre"}</span> : ''}
              {job?.benefits?.length ?
                <div className="job-content-benefits">
                  {job.benefits.map((item: any, index: number) => {
                    return (
                      <div className="job-content-benefits-item" key={index}>{item.name}</div>
                    );
                  })}
                </div> : ''}

              <div className="job-content-ref">
                <span>{t('createdOn')} {dayjs(job.createdAt).format('DD/MM/YYYY')} - </span>
                <span>{t('reference')} : {job._id}</span>
              </div>
            </div>
          </div> : ''}
        </div> :
        // Loading
        <div>
          <div className='loader-container'>
            <div className="loader"></div>
          </div>
        </div>
      }
    </div >
  );
}