import React from "react";
import { useTranslation } from "react-i18next";
import '../Forms.scss';
import { Constants } from "../../../utils/constants.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { updateCurrentUser } from "../../../services/users.service";

export default function EditUserInfos(props: { readOnly: boolean, accountType: 'user' | 'company', data: any, refreshParent?: any; }) {
  const { t } = useTranslation('translation', { keyPrefix: 'register' });
  const navigate = useNavigate();

  const [data, setData] = React.useState<any>(props.data);
  const [formErrors, setFormErrors] = React.useState<any>({});
  const [updatedEmail, setUpdatedEmail] = React.useState(false);

  const handleFormData = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { name, value } = event.target;

    if (name === 'email') {
      setUpdatedEmail(value !== data.email);
    }

    const tempData = { ...data, [name]: value }; // ! Used to check rules before state to be updated (async)
    setData(tempData);
    checkRules(tempData);
  };

  const handleSubmit = async () => {
    console.log('🚀 > handleFormData > data:', data);

    const errors = checkRules(data);

    // Return if error
    if (Object.keys(errors).length) {
      console.log('🚀 > handleSubmit > errors:', errors);
      toast.error(t('invalidFields'));
      return;
    }

    const updateResult = await updateCurrentUser(data, navigate);
    console.log('🚀 > handleSubmit > updateResult:', updateResult);

    if (updateResult?.modifiedCount) {
      toast.success(t('editionSuccess'));
      setUpdatedEmail(false);
      // Refresh parent
      props.refreshParent();
    } else {
      toast.error(t('editionError'));
    }
  };

  const checkRules = (data: any) => {
    const rules: any = Constants.editUserInfosRules;

    // Adapt rules if email is updated
    if (updatedEmail) {
      rules.confirmEmail = {
        maxLength: 250,
        required: true,
        regex: 'email',
      };
    }

    const errors: any = {};

    // Check every rules for account data
    for (const key of Object.keys(rules)) {
      if (rules[key].required && !data[key]) {
        errors[key] = t('required');
        continue;
      }

      if (rules[key].maxLength && data[key]?.length > rules[key].maxLength) {
        errors[key] = t('maxLength', { length: rules[key].maxLength });
      }

      if (rules[key].regex) {
        if (rules[key].regex === 'email' && !Constants.checkEmail(data[key])) {
          errors[key] = t('invalidEmail');
        }

        if (rules[key].regex === 'phone' && !Constants.checkPhone(data[key])) {
          errors[key] = t('invalidPhone');
        }
      }
    }

    // Check email confirmation
    if (updatedEmail && data['email'] !== data['confirmEmail']) {
      errors['confirmEmail'] = t('emailConfirmationError');
    }

    setFormErrors(errors);

    return errors;
  };

  return (
    <div>
      <form className="edit-infos-form">
        {/* Name + lastname */}
        <div className="edit-infos-form-row">
          <div className="edit-infos-form-item flex-1">
            <label>{t('firstName')}*</label>
            <input
              type="text"
              name="firstName"
              value={data.firstName ?? ''}
              readOnly={props.readOnly}
              disabled={props.readOnly}
              onChange={handleFormData}
            />
            {formErrors?.firstName ? <span className="input-error">{formErrors?.firstName}</span> : ''}
          </div>

          <div className="edit-infos-form-item flex-1">
            <label>{t('lastName')}*</label>
            <input
              type="text"
              name="lastName"
              value={data.lastName ?? ''}
              readOnly={props.readOnly}
              disabled={props.readOnly}
              onChange={handleFormData}
            />
            {formErrors?.lastName ? <span className="input-error">{formErrors?.lastName}</span> : ''}
          </div>
        </div>

        {/* Email */}
        <div className="edit-infos-form-item flex-1">
          <label>{t('email')}*</label>
          <input
            type="text"
            name="email"
            value={data.email ?? ''}
            readOnly={props.readOnly}
            disabled={props.readOnly}
            onChange={handleFormData}
          />
          {formErrors?.email ? <span className="input-error">{formErrors?.email}</span> : ''}
        </div>
        {updatedEmail ?
          <div className="edit-infos-form-item flex-1">
            <label>{t('confirmEmail')}*</label>
            <input
              type="text"
              name="confirmEmail"
              readOnly={props.readOnly}
              disabled={props.readOnly}
              onChange={handleFormData}
            />
            {formErrors?.confirmEmail ? <span className="input-error">{formErrors?.confirmEmail}</span> : ''}
          </div> : ''}
      </form>

      {!props.readOnly ?
        <button type="submit" className="submit-btn" onClick={handleSubmit}>{t('validate')} <FontAwesomeIcon icon="check" /></button> : ''}
    </div>
  );
}