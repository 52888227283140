import i18n from "../i18n";
import { Constants } from "../utils/constants.utils";
import { toast } from 'react-toastify';

let lastLogout: number;

/**
 * Create account
 * @param {*} data
 * @returns
 */
export async function register(data: any) {
  try {
    const response = await fetch(
      `${Constants.API_URL}/api/v1/accounts`,
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data)
      }
    );

    if (response.status === 201) {
      return { success: true };
    } else {
      const text = await response.text();
      return { error: JSON.parse(text), status: response.status };
    }
  } catch (error) {
    console.log('🚀 > register > error:', error);
  }
}

/**
 * User login with option to save token or not (admin)
 * @param {*} email
 * @param {*} password
 * @param {*} saveToken
 * @returns
 */
export async function login(email: string, password: string, saveToken: boolean) {
  try {
    const response = await fetch(
      `${Constants.API_URL}/api/v1/accounts/login`,
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          email,
          password
        })
      }
    );

    if (response.status === 201) {
      const json = await response.json();

      if (saveToken) {
        localStorage.setItem('token', json.access_token);
      }

      return { success: true, token: json.access_token };
    } else {
      const text = await response.text();
      return { error: JSON.parse(text), status: response.status };
    }
  } catch (error) {
    console.log('🚀 > login > error:', error);
  }
}

export function getToken() {
  return localStorage.getItem('token');
}

export function isConnected() {
  const token = localStorage.getItem('token');

  if (token) {
    return true;
  }
}

/**
 * Disconnect user and show toast if session expired
 * @param {*} navigate
 * @param {*} route
 * @param {*} displayToast
 */
export function logout(navigate: any, route: string, displayToast: boolean) {
  localStorage.clear();

  if (navigate && route) {
    navigate(route);
  }

  if (displayToast) {
    // Check last logout date to prevent multiple toasts display
    if (lastLogout) {
      const diff = new Date().getTime() - new Date(lastLogout).getTime();

      // If < 1 min, return without toast
      if (diff < 60 * 1000) {
        return;
      }
    } else {
      lastLogout = new Date().getTime();
    }

    if (i18n.language === 'en') {
      toast.error("Your session has expired, please log in again.");
    } else {
      toast.error("Votre session a expiré, veuillez vous reconnecter.");
    }
  }
}

