import React from "react";
import { useTranslation } from "react-i18next";
import '../Forms.scss';
import { Constants } from "../../../utils/constants.utils";
import { updateCompany } from "../../../services/companies.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

export default function EditCompanyInfos(props: { readOnly: boolean, data: any, refreshParent?: any; }) {
  const { t } = useTranslation('translation', { keyPrefix: 'register' });
  const navigate = useNavigate();

  const [data, setData] = React.useState<any>(props.data);
  const [formErrors, setFormErrors] = React.useState<any>({});

  const handleFormData = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { name, value } = event.target;

    const tempData = { ...data, [name]: value }; // ! Used to check rules before state to be updated (async)
    setData(tempData);
    checkRules(tempData);
  };

  const handleSubmit = async () => {
    console.log('🚀 > handleFormData > data:', data);

    const errors = checkRules(data);

    // Return if error
    if (Object.keys(errors).length) {
      console.log('🚀 > handleSubmit > errors:', errors);
      toast.error(t('invalidFields'));
      return;
    }

    const updateResult = await updateCompany({
      name: data.name,
      siret: data.siret,
      address: data.address,
      zipCode: data.zipCode,
      city: data.city,
      country: data.country,
      phone: data.phone
    }, navigate);

    if (updateResult?.modifiedCount) {
      toast.success(t('companyInfosEditionSuccess'));
      // Refresh parent
      props.refreshParent();
    } else {
      toast.error(t('companyInfosEditionError'));
    }
  };

  const checkRules = (data: any) => {
    const rules: any = Constants.editCompanyInfosRules;
    const errors: any = {};

    // Check every rules for account data
    for (const key of Object.keys(rules)) {
      if (rules[key].required && !data[key]) {
        errors[key] = t('required');
        continue;
      }

      if (rules[key].maxLength && data[key]?.length > rules[key].maxLength) {
        errors[key] = t('maxLength', { length: rules[key].maxLength });
      }

      if (rules[key].regex) {
        if (rules[key].regex === 'phone' && !Constants.checkPhone(data[key])) {
          errors[key] = t('invalidPhone');
        }
      }
    }

    setFormErrors(errors);

    return errors;
  };

  return (
    <div>
      <form className="edit-infos-form">
        {/* Name + siret */}
        <div className="edit-infos-form-row">
          <div className="edit-infos-form-item flex-1">
            <label>{t('companyName')}*</label>
            <input
              type="text"
              name="name"
              value={data.name ?? ''}
              readOnly={props.readOnly}
              disabled={props.readOnly}
              onChange={handleFormData}
            />
            {formErrors?.name ? <span className="input-error">{formErrors?.name}</span> : ''}
          </div>

          <div className="edit-infos-form-item flex-1">
            <label>Siret</label>
            <input
              type="text"
              name="siret"
              value={data.siret ?? ''}
              readOnly={props.readOnly}
              disabled={props.readOnly}
              onChange={handleFormData}
            />
            {formErrors?.siret ? <span className="input-error">{formErrors?.siret}</span> : ''}
          </div>
        </div>

        {/* Address */}
        <div className="edit-infos-form-row">
          <div className="edit-infos-form-item flex-3">
            <label>{t('address')}*</label>
            <input
              type="text"
              name="address"
              value={data.address ?? ''}
              readOnly={props.readOnly}
              disabled={props.readOnly}
              onChange={handleFormData}
            />
            {formErrors?.address ? <span className="input-error">{formErrors?.address}</span> : ''}
          </div>

          <div className="edit-infos-form-item flex-1">
            <label>{t('zipCode')}*</label>
            <input
              type="text"
              name="zipCode"
              value={data.zipCode ?? ''}
              readOnly={props.readOnly}
              disabled={props.readOnly}
              onChange={handleFormData}
            />
            {formErrors?.zipCode ? <span className="input-error">{formErrors?.zipCode}</span> : ''}
          </div>
        </div>

        <div className="edit-infos-form-row">
          <div className="edit-infos-form-item flex-2">
            <label>{t('phone')}*</label>
            <input
              type="text"
              name="city"
              value={data.city ?? ''}
              readOnly={props.readOnly}
              disabled={props.readOnly}
              onChange={handleFormData}
            />
            {formErrors?.city ? <span className="input-error">{formErrors?.city}</span> : ''}
          </div>

          <div className="edit-infos-form-item flex-2">
            <label>{t('country')}*</label>
            <input
              type="text"
              name="country"
              value={data.country ?? ''}
              readOnly={props.readOnly}
              disabled={props.readOnly}
              onChange={handleFormData}
            />
            {formErrors?.country ? <span className="input-error">{formErrors?.country}</span> : ''}
          </div>

          <div className="edit-infos-form-item flex-3">
            <label>{t('phone')}*</label>
            <input
              type="text"
              name="phone"
              value={data.phone ?? ''}
              readOnly={props.readOnly}
              disabled={props.readOnly}
              onChange={handleFormData}
            />
            {formErrors?.phone ? <span className="input-error">{formErrors?.phone}</span> : ''}
          </div>
        </div>
      </form>

      {!props.readOnly ?
        <button type="submit" className="submit-btn" onClick={handleSubmit}>{t('validate')} <FontAwesomeIcon icon="check" /></button> : ''}
    </div>
  );
}