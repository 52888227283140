import React from "react";
import { useTranslation } from "react-i18next";
import './Company.scss';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { getCompanyById, getCompanyOffers } from "../../services/public.service";
import Card from "../../components/card/Card";

export default function Company() {
  const { t } = useTranslation('translation', { keyPrefix: 'company' });
  const navigate = useNavigate();

  const [jobs, setJobs] = React.useState<any>();
  const [company, setCompany] = React.useState<any>();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const companyId = queryParameters.get("id");

    if (companyId?.length) {
      loadCompanyAndJobs(companyId ?? '');
    }
  }, []);

  const loadCompanyAndJobs = async (companyId: string) => {
    setLoading(true);

    const companyData = await getCompanyById(companyId);
    console.log('🚀 > loadCompanyAndJobs > companyData:', companyData)

    if (companyData && Object.keys(companyData).length) {
      setCompany(companyData);

      const companyJobs = await getCompanyOffers(companyId);
      console.log('🚀 > loadCompanyAndJobs > companyJobs:', companyJobs)
      setJobs(companyJobs);
    } else {
      toast.error(t('notFound'));
      navigate('/');
    }

    setLoading(false);
  };

  return (
    <div className="container">
      {!loading ?
        <div>
          {company ? <div className="company" >
            {/* Title */}
            <div className="company-header">
              <h1>{company.name}</h1>
            </div>

            {/* Content */}
            <div className="company-content">
              <span className="company-content-benefits-title">{t('benefits')}</span>

              {company?.benefits?.length ?
                <div className="company-content-benefits">
                  {company.benefits.map((item: any, index: number) => {
                    return (
                      <div className="company-content-benefits-item" key={index}>{item.name}</div>
                    );
                  })}
                </div> : ''}
            </div>

            {/* Jobs */}
            {/* Last jobs */}
            <div className="company-jobs">
              <h2>{t('lastOffers')}</h2>

              <div className="card-list">
                {jobs?.length ? jobs.map((offer: any, key: string) => {
                  return (
                    <Card key={key} data={offer} type='job' />
                  );
                }) : ''}
              </div>
            </div>
          </div> : ''}
        </div> :
        // Loading
        <div>
          <div className='loader-container'>
            <div className="loader"></div>
          </div>
        </div>
      }
    </div >
  );
}