import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import './Card.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Card(props: any) {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'job' });

  const getFakeImage = () => {
    const random = Math.floor(Math.random() * (5));

    const images = [
      'https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      'https://images.pexels.com/photos/233698/pexels-photo-233698.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      'https://images.pexels.com/photos/302769/pexels-photo-302769.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      'https://images.pexels.com/photos/936722/pexels-photo-936722.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      'https://images.pexels.com/photos/164572/pexels-photo-164572.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    ];

    return images[random];
  };

  return (
    <div className="card">
      {props.type === 'company' ?
        <div className="card-img">
          <img src={props.data?.img ? props.data.img : getFakeImage()} />
        </div> : ''
      }

      <div className="card-content">
        {props.type === 'job' ?
          <h2>{props.data?.title}</h2> :
          <h2>{props.data?.name}</h2>
        }

        {props.type === 'job' ?
          <div className="card-content-job">
            {props.data?.contractType ?
              <span className="card-content-job-type">{t(props.data.contractType)}</span> : ''}
            {props.data?.company || props.data?.location ?
              <span className="card-content-job-location">{props.data.company ? props.data.company + ',' : ''} {props.data.location} <FontAwesomeIcon icon="map-pin" /></span> : ''}

            {/* // TODO afficher tous les avantages ? ou seulement quelque-uns */}
            {/* Benefits */}
            {/* {props.data?.benefits?.length ?
            <div className="card-content-job-tags">
              {props.data.benefits.map((benefit: any, index: number) => {
                return <span key={index}>{benefit.name}</span>;
              })}
            </div> : ''
          } */}
          </div> :
          <div className="card-content-job">
            {props.data?.city && props.data?.country ?
              <span className="card-content-job-location">{props.data.city}, {props.data.country} <FontAwesomeIcon icon="map-pin" /></span> : ''}

            <span>{props.data.offersCounter} {t('offer')}{props.data.offersCounter > 1 ? 's' : ''} {t('available')}{props.data.offersCounter > 1 ? 's' : ''}</span>
          </div>
        }

        {props.type === 'job' ?
          <button onClick={() => navigate(`/jobs?id=${props.data._id}`)}>{t('consult')} <FontAwesomeIcon icon="arrow-right" /></button> :
          <button onClick={() => navigate(`/companies?id=${props.data._id}`)}>{t('consult')} <FontAwesomeIcon icon="arrow-right" /></button>
        }
      </div>
    </div>
  );
}

