import React from "react";
import { useTranslation } from "react-i18next";
import '../Forms.scss';
import { Constants } from "../../../utils/constants.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { updateCurrentPassword } from "../../../services/users.service";

export default function EditPassword(props: { readOnly: boolean, refreshParent?: any; }) {
  const { t } = useTranslation('translation', { keyPrefix: 'register' });
  const navigate = useNavigate();

  const [data, setData] = React.useState<any>();
  const [formErrors, setFormErrors] = React.useState<any>({});

  const handleFormData = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { name, value } = event.target;

    const tempData = { ...data, [name]: value }; // ! Used to check rules before state to be updated (async)
    setData(tempData);
    checkRules(tempData);
  };

  const handleSubmit = async () => {
    console.log('🚀 > handleFormData > data:', data);

    const errors = checkRules(data);

    // Return if error
    if (Object.keys(errors).length) {
      console.log('🚀 > handleSubmit > errors:', errors);
      toast.error(t('invalidFields'));
      return;
    }

    const updateResult = await updateCurrentPassword(data, navigate);

    if (updateResult?.modifiedCount) {
      toast.success(t('passwordEditionSuccess'));
      setData({})
      // Refresh parent
      props.refreshParent();
    } else {
      toast.error(t('passwordEditionError'));
    }
  };

  const checkRules = (data: any) => {
    const rules: any = Constants.editPasswordRules;
    const errors: any = {};

    // Check every rules for account data
    for (const key of Object.keys(rules)) {
      if (rules[key].required && !data[key]) {
        errors[key] = t('required');
        continue;
      }

      if (rules[key].maxLength && data[key]?.length > rules[key].maxLength) {
        errors[key] = t('maxLength', { length: rules[key].maxLength });
      }
    }

    setFormErrors(errors);

    return errors;
  };

  return (
    <div>
      <form className="edit-infos-form">
        {/* Password */}
        <div className="edit-infos-form-row">
          <div className="edit-infos-form-item flex-1">
            <label>{t('oldPassword')}*</label>
            <input
              type="password"
              name="oldPassword"
              readOnly={props.readOnly}
              disabled={props.readOnly}
              onChange={handleFormData}
            />
            {formErrors?.oldPassword ? <span className="input-error">{formErrors?.oldPassword}</span> : ''}
          </div>
          <div className="edit-infos-form-item flex-1">
            <label>{t('newPassword')}*</label>
            <input
              type="password"
              name="newPassword"
              readOnly={props.readOnly}
              disabled={props.readOnly}
              onChange={handleFormData} />
            {formErrors?.newPassword ? <span className="input-error">{formErrors?.newPasword}</span> : ''}
          </div>
        </div>
      </form>

      {!props.readOnly ?
        <button type="submit" className="submit-btn" onClick={handleSubmit}>{t('validate')} <FontAwesomeIcon icon="check" /></button> : ''}
    </div>
  );
}