import { Constants } from "../utils/constants.utils";

/**
 * Get one offer
 * @param id
 * @returns
 */
export async function getOfferById(id: string) {
  try {
    const response = await fetch(
      `${Constants.API_URL}/api/v1/public/offers/${id}`,
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
        method: 'GET',
      }
    );

    if (response.status === 200) {
      return response.json();
    } else {
      return { error: JSON.parse(await response.text()), status: response.status };
    }
  } catch (error) {
    console.log('🚀 > getOfferById > error:', error);
  }
}

/**
 * Get all offers
 * @param limit
 * @param latest
 * @returns
 */
export async function getAllOffers(limit: number, latest: boolean) {
  try {
    const response = await fetch(
      `${Constants.API_URL}/api/v1/public/offers?limit=${limit}&latest=${latest}`,
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
        method: 'GET',
      }
    );

    if (response.status === 200) {
      return response.json();
    } else {
      return { error: JSON.parse(await response.text()), status: response.status };
    }
  } catch (error) {
    console.log('🚀 > getAllOffers > error:', error);
  }
}

/**
 * Get offers count
 * @returns
 */
export async function getOffersCount() {
  try {
    const response = await fetch(
      `${Constants.API_URL}/api/v1/public/offers/count`,
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
        method: 'GET',
      }
    );

    if (response.status === 200) {
      return response.json();
    } else {
      return { error: JSON.parse(await response.text()), status: response.status };
    }
  } catch (error) {
    console.log('🚀 > getAllOffersCount > error:', error)
  }
}

/**
 * Get one company
 * @param id
 * @returns
 */
export async function getCompanyById(id: string) {
  try {
    const response = await fetch(
      `${Constants.API_URL}/api/v1/public/companies/${id}`,
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
        method: 'GET',
      }
    );

    if (response.status === 200) {
      return response.json();
    } else {
      return { error: JSON.parse(await response.text()), status: response.status };
    }
  } catch (error) {
    console.log('🚀 > getCompanyById > error:', error);
  }
}

/**
 * Get all companies
 * @param limit
 * @param sort
 * @returns
 */
export async function getAllCompanies(limit: number, sort: boolean) {
  try {
    const response = await fetch(
      `${Constants.API_URL}/api/v1/public/companies?limit=${limit}&sort=${sort}`,
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
        method: 'GET',
      }
    );

    if (response.status === 200) {
      return response.json();
    } else {
      return { error: JSON.parse(await response.text()), status: response.status };
    }
  } catch (error) {
    console.log('🚀 > getAllCompanies > error:', error);
  }
}

/**
 * Get one company offers
 * @param id
 * @returns
 */
export async function getCompanyOffers(id: string) {
  try {
    const response = await fetch(
      `${Constants.API_URL}/api/v1/public/companies/${id}/offers`,
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
        method: 'GET',
      }
    );

    if (response.status === 200) {
      return response.json();
    } else {
      return { error: JSON.parse(await response.text()), status: response.status };
    }
  } catch (error) {
    console.log('🚀 > getCompanyOffers > error:', error);
  }
}

/**
 * Get all available benefits
 * @returns
 */
export async function getBenefits() {
  try {
    const response = await fetch(
      `${Constants.API_URL}/api/v1/public/benefits`,
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
        method: 'GET',
      }
    );

    if (response.status === 200) {
      return response.json();
    } else {
      return { error: JSON.parse(await response.text()), status: response.status };
    }
  } catch (error) {
    console.log('🚀 > getBenefits > error:', error);
  }
}